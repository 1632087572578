import { Button, ButtonProps, Typography } from "@mui/material";
import { FingerprintOutlined } from "@mui/icons-material";
import { useAuth } from "../providers";

const LogoutButton = (props: ButtonProps) => {
  const { logout } = useAuth();
  let returnTo: string | undefined;

  if (window?.location) {
    returnTo = window.location.origin;
  }

  const onClick = async () => await logout({ returnTo });

  return (
    <Button
      {...props}
      sx={{ textTransform: "none", ...props?.sx }}
      variant="outlined"
      color="warning"
      size="large"
      startIcon={<FingerprintOutlined />}
      onClick={onClick}
    >
      <Typography variant="h6">Sair da conta</Typography>
    </Button>
  );
};

export default LogoutButton;
