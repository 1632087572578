import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Checkbox,
} from "@mui/material";
import pkg from "../../../package.json";
import { browserName } from "react-device-detect";
import {
  ComputerOutlined,
  InsertDriveFile,
  DarkModeOutlined,
} from "@mui/icons-material";

import Profile from "../../components/Profile";
import { useDynamicTheme } from "../../providers/DynamicThemeProvider";

export default function ProfilePage() {
  const { currentColorScheme, changeTheme } = useDynamicTheme();

  const toggleDarkMode = () => {
    changeTheme(currentColorScheme === "dark" ? "light" : "dark");
  };

  const isDarkMode = currentColorScheme === "dark";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Profile></Profile>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Sistema</Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemIcon>
                  <ComputerOutlined />
                </ListItemIcon>
                <ListItemText
                  primary="Browser"
                  secondary={browserName}
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText
                  primary="Versão do App"
                  secondary={pkg.version}
                ></ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h6">Configurações</Typography>
            <Divider />
            <List>
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={toggleDarkMode}
                    checked={isDarkMode}
                    inputProps={{ "aria-labelledby": "Habilita o modo escuro" }}
                  />
                }
              >
                <ListItemIcon>
                  <DarkModeOutlined />
                </ListItemIcon>
                <ListItemText
                  primary="Habilitar modo escuro?"
                  secondary="Visualize a aplicação no modo escuro sempre que utilizar este dispositivo"
                ></ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
