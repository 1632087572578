import { Fragment, useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Analysis, SDK } from "@zrp/client";
import CommentHistory from "./CommentHistory";
import AnalysisStatusChip from "../../AnalysisStatusChip";
import moment from "moment";

function formatDate(date: Date): string {
  return moment(date).format('lll')
}

export default function AnalysisHistoryList(props: { 
  analysis: Analysis
  commentaries: any[]
  deleteAlert: (commentaryId: number | undefined) => void
 }): JSX.Element {
  const [statusUpdates, setStatusUpdates] = useState<any>();
  const [statusAndHistoryUpdates, setStatusAndHistoryUpdates] = useState<any>();
  
  const { analysis, commentaries, deleteAlert } = props;

  async function getAllUpdates(analysisId: number | undefined) {
    if (analysisId){
      const res = await SDK.analysis.history.getAllStatusUpdates(analysisId);
      setStatusUpdates(res);
    }
  }

  
  useEffect(() => {
    function fullUpdates() {
      if (commentaries && statusUpdates){
        const allStatusUpdatesWithDate = statusUpdates.map((update: any) => {
          if (update?.to?.updatedAt) {
            const { updatedAt, ...withoutUpdatedAt} = update.to;
            return {
              to: withoutUpdatedAt,
              from: update.from,
              updatedAt,
            }
          } return update
        })
        const updates = [...commentaries, ...allStatusUpdatesWithDate]
        updates.sort((a, b) => {
          if (a.updatedAt && b.updatedAt) {
            return new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime() ? -1 : 1;
          } return 0;
        })
        //TODO: Tá setado como any porque deu um problema de tipagem gigantesco e o TS estava bugando loucamente.
        setStatusAndHistoryUpdates(updates as any)
      }
      return [];
    }  
    try {
      fullUpdates();
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${error.message}`);
    }
  }, [statusUpdates, commentaries]);

  useEffect(() => {
    try {
      getAllUpdates(analysis.id);
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${error.message}`);
    }
  }, [analysis]);

  
  return (
    <Fragment>
      { statusAndHistoryUpdates ? 
              statusAndHistoryUpdates.map((update: any) => {
                if(update.to) {
                  return (
                <>
                <Typography sx={{ color: "#A4A4AD" }} variant="subtitle2">
                  - Alteração de status em {update.updatedAt ? formatDate(new Date(update.updatedAt)) : ''}
                </Typography>
                <Stack mb={2} py={2} flexDirection="row" alignItems="center" gap={2}>
                  De&nbsp;
                  <AnalysisStatusChip status={ update?.from?.changes.status || ""}></AnalysisStatusChip>
                  para
                  <AnalysisStatusChip status={ update?.to?.changes.status || "" }></AnalysisStatusChip>
                </Stack>
              </>
                  )
                } return (
                    <>
                    <CommentHistory commentary={update}
                    deleteCommentary={() => deleteAlert(update?.id)}/>
                    </>
                  )
              }
              )
            : <div></div> }

    </Fragment>
  )
}