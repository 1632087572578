import React from "react";
import { TextFieldProps } from "@mui/material/TextField";
import * as S from "./styles";

type Props = {
  label: string;
  icon?: React.FC;
};

const Input = (props: TextFieldProps & Props) => {
  const { label, icon: Icon, ...inputProps } = props;

  return (
    <S.InputWrapper>
      <S.Label
        shrink
        htmlFor="zrp-input"
        sx={{ display: "flex", alignItems: "center" }}
        error={Boolean(inputProps.error)}
      >
        {Icon && <Icon />}
        <span>{label}</span>
      </S.Label>
      <S.TextField {...inputProps} id="zrp-input" />
    </S.InputWrapper>
  );
};

export default Input;
