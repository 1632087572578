import { Axios, AxiosRequestConfig } from 'axios';

import {
  RequestFilter,
  ObjectivesService,
  KeyResultsService,
  EmployeesService,
  CareerPathCriteriaService,
  DepartmentService,
  AcademyService,
  AnalysisService,
  ProgressService,
} from './services';

import {
  Objective,
  KeyResult,
  Version,
  Employee,
  Course,
  Module,
  Tag,
  Topic,
  Analysis,
  Criteria,
  Progress,
  LevelProgress,
} from './models';

import { Logger, NullLogger } from './utils';
import { CapsService } from './services/CapsService';

export {
  RequestFilter,
  Objective,
  KeyResult,
  Version,
  Employee,
  Course,
  Module,
  Tag,
  Topic,
  Analysis,
  Criteria,
  Progress,
  LevelProgress,
};

export interface Options {
  logging?: boolean;
  logger?: Logger;
  accessToken: string | Promise<string>;
  clientOptions?: Partial<AxiosRequestConfig>;
}

export class SDK {
  private static instance: SDK;
  private static isInitialized: boolean = false;
  public static results: KeyResultsService;
  public static objectives: ObjectivesService;
  public static employees: EmployeesService;
  public static caps: CapsService;
  public static careerPathCriteria: CareerPathCriteriaService;
  public static department: DepartmentService;
  public static academy: AcademyService;
  public static analysis: AnalysisService;
  public static progress: ProgressService;

  private client: Axios;
  private logging: boolean = true;
  private logger?: Logger;
  private static accessToken: string;

  private constructor(opts: Options) {
    const { accessToken, logger, logging, clientOptions } = opts;

    this.logging = logging != undefined ? logging : __DEV__;

    this.logger = this.logging ? logger : new NullLogger();

    // Compute base URL from environment
    const baseURL: string = __DEV__ ? 'http://localhost:3000' : 'https://api.zrp.com.br';

    const defaults: AxiosRequestConfig = {
      baseURL,
    };

    const axiosOptions: AxiosRequestConfig = {
      ...defaults,
      ...(clientOptions ?? {}),
    };

    this.client = new Axios(axiosOptions);

    this.client.interceptors.request.use(async (config) => {
      const token = (await accessToken) ?? '';
      config.headers = {
        ...(config.headers || {}),
        ...(token
          ? {
            authorization: `Bearer ${token}`,
          }
          : {}),
      };

      return config;
    });

    // Init services
    SDK.results = new KeyResultsService(this.client, this.logger);
    SDK.objectives = new ObjectivesService(this.client, this.logger);
    SDK.employees = new EmployeesService(this.client, this.logger);
    SDK.caps = new CapsService(this.client, this.logger);
    SDK.careerPathCriteria = new CareerPathCriteriaService(
      this.client,
      this.logger,
    );
    SDK.department = new DepartmentService(this.client, this.logger);
    SDK.academy = new AcademyService(this.client, this.logger);
    SDK.analysis = new AnalysisService(this.client, this.logger);
    SDK.progress = new ProgressService(this.client, this.logger);
  }

  static init(options: Options): void {
    if (SDK.instance) {
      SDK.instance.client.interceptors.request.eject(0);

      SDK.instance.client.interceptors.request.use(async (config) => {
        const token = (await options.accessToken) ?? '';
        config.headers = {
          ...(config.headers || {}),
          ...(token != ''
            ? {
              authorization: `Bearer ${token}`,
            }
            : {}),
        };

        SDK.isInitialized = true;
        return config;
      });
    } else {
      SDK.isInitialized = true;
      SDK.instance = new SDK(options);
    }
  }

  static updateToken(token: string | null): void {
    SDK.accessToken = token? token : '';

    const interceptors = SDK.instance.client.interceptors;

    interceptors.request.eject(0);

    interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.headers = {
        ...(config.headers || {}),
        ...(SDK.accessToken
          ? {
              authorization: `Bearer ${SDK.accessToken}`,
            }
          : {}),
      };

      return config;
    });
  }

  static isSDKInitialized(): boolean {
    return SDK.isInitialized;
  }
}
