import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { RoundedTextField } from "src/components/Input/styles";

export default function EmployeeFiltersBar(props: {
  filters?: any;
  handleNameFilter: Function;
  handlePdcLevelFilter: Function;
  selectedPdcLevel: string;
  handleDepartmentFilter: Function;
  selectedDepartment: string;
  handleEmployeesSorting: Function;
  resultCounter?: Number;
}) {
  const {
    filters,
    handleNameFilter,
    handleDepartmentFilter,
    selectedDepartment,
    handleEmployeesSorting,
    resultCounter,
  } = props;
  return (
    <Box display='flex' justifyContent='space-between' width='100%' paddingX={4} flexWrap='wrap' gap={2}>
        <Box display='flex' alignItems='center' gap={2} flexWrap='wrap'>
          <RoundedTextField
            sx={{ borderRadius: "50px" }}
            placeholder={"Busca por nome"}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
              <Search></Search>
            </InputAdornment>
            )}}
            onChange={e => handleNameFilter(e)}
          />
          <RoundedTextField
            select
            sx={{ minWidth: "280px", fieldset: {borderColor:'red'} }}
            onChange={e => handleDepartmentFilter(e)}
            label="Área"
            value={selectedDepartment}
          >
            <MenuItem value="">Nenhum</MenuItem>
            {Object.keys(filters).length &&
              filters.departments.map((department: string) => (
                <MenuItem value={department}>{department}</MenuItem>
              ))}
          </RoundedTextField>
          {resultCounter !== undefined && (
            <Typography sx={{ display: "inline-block" }} variant="body1">
              <b>{resultCounter} Resultados</b>
            </Typography>
          )}
        </Box>
        <Box display='flex' alignItems='center' gap={2} flexWrap='wrap'>
          <Typography sx={{ display: "inline-block" }} variant="body1">
            Ordenar por:
          </Typography>
          <RoundedTextField
            select
            defaultValue='ordemAlfabeticaCrescente'
            sx={{ minWidth: "280px" }}
            onChange={e => {
              handleEmployeesSorting(e);
            }}
          >
            <MenuItem>Sem ordenação</MenuItem>
            <MenuItem value="ordemAlfabeticaCrescente">
              Ordem alfabética crescente
            </MenuItem>
            <MenuItem value="ordemAlfabeticaDecrescente">
              Ordem alfabética decrescente
            </MenuItem>
            <MenuItem value="dataContratacaoCrescente">
              Data de contratação crescente
            </MenuItem>
            <MenuItem value="dataContratacaoDecrescente">
              Data de contratação decrescente
            </MenuItem>
          </RoundedTextField>
        </Box>
    </Box>
  );
}
