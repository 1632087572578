import { createElement, useState } from "react";
import { useNavigate } from "react-router";

import {
  IconProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { MENU_ITEMS } from "../../config/routes";
import { useWindowDimensions } from "../../hooks";
import { useEmployee } from "src/providers";

const calculateCurrentMenuItem = () => {
  const pathname = window.location.pathname
  let item = MENU_ITEMS.find(item => {
    if(Object.hasOwn(item, 'collapse')){
      return item.collapse?.find(collapse => collapse.to === pathname)
    }
    return item.to === pathname
  });
  return item?.title || MENU_ITEMS[0].title
}

export default function Menu(
  props: IconProps & { open?: boolean }
): JSX.Element {
  const [menuExpandable, setMenuExpandable] = useState<{
    [key: string]: boolean;
  }>({});
  const { employee } = useEmployee();

  const [selectedItem, setSelectedItem] = useState(calculateCurrentMenuItem());

  const { open, ...iconProps } = props;

  const nav = useNavigate();

  const dimensions = useWindowDimensions();

  const handleCollapseItem = (title: string): void =>
    setMenuExpandable({ ...menuExpandable, [title]: !menuExpandable[title] });

  const children = [];

  const calculateMenuItems = () => {
    if(employee?.departament === "Recursos Humanos" || employee?.job === "Sócio") {
      return MENU_ITEMS
    }
    return MENU_ITEMS.filter(
      (item) => item.title !== "Dossiê Geral"
    );
  }

  for (const item of calculateMenuItems()) {
    children.push(
      <Tooltip
        key={item.to}
        title={!open ? item.title : ""}
        followCursor={dimensions.width > 600}
        placement="right"
      >
        {item.collapse ? (
          <>
            <ListItemButton
              key={item.title}
              onClick={() => {
                handleCollapseItem(item.title);
                setSelectedItem(item.title);
              }}
              sx={
                selectedItem === item.title
                ? { border: "3px solid #9CCC62", borderRadius: "50px", mx: 1, px: 1}
                : {}
              }
            >
              {item.icon && (
                <ListItemIcon>
                  {createElement(item.icon, {
                    ...iconProps,
                    sx: {
                      ...iconProps.sx,
                      width: theme => theme.spacing(4),
                    },
                  } as IconProps)}
                </ListItemIcon>
              )}
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ fontSize: 14 }}
              />
              {menuExpandable[item.title] ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse
              in={menuExpandable[item.title]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {item.collapse?.map(subitem => (
                  <ListItemButton
                    key={subitem.title}
                    onClick={() => nav(subitem.to)}
                    sx={{ pl: 4 }}
                  >
                    {subitem.icon && (
                      <ListItemIcon>
                        {createElement(subitem.icon, {
                          ...iconProps,
                          sx: {
                            ...iconProps.sx,
                            width: theme => theme.spacing(4),
                          },
                        } as IconProps)}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={subitem.title}
                      primaryTypographyProps={{ fontSize: 14 }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItemButton
            key={item.title}
            onClick={() => {
              nav(item.to);
              setSelectedItem(item.title);
            }}
            sx={
              selectedItem === item.title
              ? { border: "3px solid #9CCC62", borderRadius: "50px", mx: 1, px: 1}
              : {}
            }
          >
            {item.icon && (
              <ListItemIcon>
                {createElement(item.icon, {
                  ...iconProps,
                  sx: {
                    ...iconProps.sx,
                    width: theme => theme.spacing(4),
                  },
                } as IconProps)}
              </ListItemIcon>
            )}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ fontSize: 14 }}
            />
          </ListItemButton>
        )}
      </Tooltip>
    );
  }

  // The marginRight -6px compensates on overflows
  return (
    <>
      <List
        sx={{
          padding: 0,
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "-6px",
        }}
        component="nav"
        children={children}
      />
    </>
  );
}
