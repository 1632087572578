import { ThemeOptions, createTheme, LinkProps } from "@mui/material";
import React from "react";
import { mergeDeep } from "../utils/data";
import { Link as RouterLink } from "react-router-dom";

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props as any & { href?: string };

  return <RouterLink ref={ref} to={href ?? "/"} {...other} />;
});

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#9CCC62",
    },
    secondary: {
      main: "#F15E5E",
    },
    error: {
      main: "#F15E5E",
    },
    success: {
      main: "#9CCC62",
    },
    warning: {
      main: "#F6A848",
    },
    info: {
      main: "#6799CC",
    },
    background: {
      paper: "#000000",
      default: "#232323",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255,255,255,0.7)",
      disabled: "rgba(255,255,255,0.4)",
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF30",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          background: "#232323",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#000000",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#434343",
        },
      },
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#79AF65",
    },
    secondary: {
      main: "#D75956",
    },
    error: {
      main: "#D75956",
    },
    success: {
      main: "#79AF65",
    },
    warning: {
      main: "#EC8D3E",
    },
    info: {
      main: "#5884C3",
    },
    background: {
      paper: "#F9F9F9",
      default: "#F7EEE2",
    },
    text: {
      primary: "#000",
      secondary: "#232323",
      disabled: "#333333",
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "#00000030",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          background: "#F7EEE2",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#79AF65",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#9CCC62",
        },
      },
    },
  },
};

/**
 * Configure the application theme
 */
export const themeOptions: ThemeOptions = {
  shape: {
    // borderRadius: 32,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          scrollbarWidth: "thin",
        },
        "*::-webkit-scrollbar": {
          position: "fixed",
          width: "6px",
          height: "6px",
          background: "#232323",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#000000",
          transition: "background 300ms",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#434343",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(2px)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
          boxShadow: "none",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps & { component: any },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
};

export const theme = createTheme(
  mergeDeep<ThemeOptions>({}, themeOptions, darkThemeOptions)
);
export default theme;
