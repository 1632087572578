/**
 * Format date object as UTC date string.
 * 
 * @param date
 * @returns {string}
 */
export function formatUTCDate(date: Date): string {
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}
