import { Axios } from 'axios';

import { Employee } from 'src/models/Employee';

import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class EmployeesService extends BaseService<Employee> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/employees';
  }
}
