import { Employee, SDK } from "@zrp/client";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

interface IEmployeeContext {
  employee: Employee;
  canAccessOptions: boolean;
}
const EmployeeContext = createContext<IEmployeeContext>(null!);

export function EmployeeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useAuth();
  const [employee, setEmployee] = useState<Employee>(null!);

  useEffect(() => {
    const getEmployee = async () => {
      if(user) {
        const email = user?.email
        if (!email) throw new Error("Usuário logado não tem email cadastrado");
        const [employee] = await SDK.employees.all({ email });

        if (!employee) throw new Error("Funcionário não encontrado.");
        setEmployee(employee);
      }
    }

    if(user){
      try {
        getEmployee();
      } catch (error: any) {
        toast.error(`Ops! Houve um erro ao buscar os dados: ${ error.message }`);
      }
    }
  }, [user]);

  const canAccessOptions = useMemo(() =>
    employee?.job === "Sócio" || employee?.departament === "Recursos Humanos",
    [employee?.departament, employee?.job]
  )

  return (
    <EmployeeContext.Provider value={{ employee, canAccessOptions }}>
        { children }
    </EmployeeContext.Provider>
  )
}

export function useEmployee(): IEmployeeContext {
  return useContext(EmployeeContext);
}
