export interface Logger {
  error(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  debug(message?: any, ...optionalParams: any[]): void;
  trace(message?: any, ...optionalParams: any[]): void;
  log(
    level: string | number | any,
    message?: any,
    ...optionalParams: any[]
  ): void;
}

export class NullLogger implements Logger {
  log() {}
  info() {}
  warn() {}
  debug() {}
  error() {}
  trace() {}
}
