/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from "@mui/material";
import { Employee, SDK } from "@zrp/client";
import { ChangeEvent, useEffect, useState, Fragment } from "react";
import DossierGeneralCard from "./components/DossierGeneralCard";
import DossierGeneralFiltersBar from './components/DossierGeneralFiltersBar';
import { toast } from "react-toastify";

export default function DossierGeneral() {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [searchedName, setSearchedName] = useState<string>("");
  const [selectedPdcLevel, setSelectedPdcLevel] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(null);
  const [selectedSortingMethod, setSelectedSortingMethod] = useState<string>('ASC');

  function filterCleaner(obj: { [key: string]: any }): { [key: string]: any } {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const filteredDepartments = [
        'Desenvolvimento',
        'Design',
        'Product Owner',
        'Quality Assurance'
      ]
      try {
        const filters = {name: searchedName, pdcLevel: selectedPdcLevel, department: selectedDepartment? selectedDepartment : filteredDepartments}
        const cleanFilter = filterCleaner(filters)
        const sorting = {name: selectedSortingMethod}
        const response = await SDK.employees.all(cleanFilter, sorting);
        const filteredEmployees = response.filter((employee: Employee) => {
          return employee.job !== "Sócio" && employee.job !== "Estagiário" && employee.dismissalAt === null;
        });
        setEmployees(filteredEmployees);
      } catch (error: any) {
        toast.error(`Ops! Houve um erro ao buscar os dados: ${ error.message }`)
      }
    };
    
    fetchData();
  }, [searchedName, selectedPdcLevel, selectedDepartment, selectedSortingMethod]);

  // Event handlers
  const handleEvent = (handler: Function) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      return handler(event.target.value)
    }
  
  return (
    <Fragment>
      <Grid container spacing={{ xl: 5, xs: 0 }} justifyContent="center">
        <Grid container spacing={3} xl={10}>
          <Grid container p={4} justifyContent="space-between">
            <DossierGeneralFiltersBar
              handleNameFilter={handleEvent(setSearchedName)}
              handlePdcLevelFilter={handleEvent(setSelectedPdcLevel)}
              handleDepartmentFilter={handleEvent(setSelectedDepartment)}
              handleSortingMethod={handleEvent(setSelectedSortingMethod)}
              selectedPdcLevel={selectedPdcLevel}
              selectedDepartment={selectedDepartment}
              selectedSortingMethod={selectedSortingMethod}
              resultCounter={employees.length}
            />
          </Grid>
            {employees.length > 0 ?
            (employees.map((employeeItem: Employee) => (
                <Grid key={employeeItem.id} item xs={12} xl={4}>
                  <DossierGeneralCard employee={employeeItem}></DossierGeneralCard>
                </Grid>
            )))
            :
            <Grid container sx={{justifyContent: 'center'}}> <h2>Sem resultados.</h2> </Grid>}
        </Grid>
      </Grid>
    </Fragment>
  );
}
