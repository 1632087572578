import { Avatar, Chip, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Employee } from "@zrp/client";
import React from "react";

export default function EmployeeTitleCard(
  props: { employee: Employee }
) {
  const {
    name,
    lastName,
    avatarImagePath,
    departament,
    pdcLevel
  } = props.employee;

  return (
    <React.Fragment>
      <Grid display="flex" flexDirection="row" gap={2} justifyContent="space-between">
        <Grid display="flex" flexDirection="column">
          <Typography variant="h6">{ name } { lastName }</Typography>
          <Stack direction="row" gap={1}>
            <Chip color="info" label={ departament } />
            <Chip color="primary" label={ pdcLevel } /> 
          </Stack>
        </Grid>
        <Grid>
          <Avatar
          sx={{ width: 64, height: 64 }}
          src={ avatarImagePath }
        ></Avatar>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
