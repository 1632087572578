// Import i18n data
import { SDK } from "@zrp/client";
import moment from "moment";
import "moment/locale/pt-br";
// Lib imports
import * as React from "react";
import { I18nextProvider } from "react-i18next";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Local imports
import Layout from "./components/layout/Layout";
import LoadingPage from "./components/LoadingPage";
import i18n from "./config/lang";
import {
  // AcademyPage,
  // CapsCreatePage,
  // CapsListPage,
  CareerPathPage,
  // DashboardPage,
  DossierGeneralPage,
  DossierPage,
  // EditCoursePage,
  EmployeesPage,
  // KeyResultPage,
  // KeyResultsPage,
  LoginPage,
  // LongTermPage,
  // NewCoursePage,
  // ObjectivesPage,
  ProfilePage,
  // ProjectsPage,
  // ShortTermPage,
  // ViewCoursePage,
  // ZrpreparePage,
} from "./pages";
import {
  AuthProvider,
  DynamicThemeProvider,
  EmployeeProvider,
  RequireAuth,
  useAuth,
  useEmployee,
} from "./providers";

// Configure libs globally
moment.locale("pt-br");

/**
 *
 * @returns
 */
function Shell() {
  const { canAccessOptions } = useEmployee();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          {/* <Route path="/" element={<DashboardPage />} /> */}
          <Route path="/" element={<Navigate to='/my-dossier' /> } />
          <Route path="profile" element={<ProfilePage />} />
          {/* <Route path="objectives" element={<ObjectivesPage />} /> */}
          {/* <Route path="okrs" element={<KeyResultsPage />}></Route> */}
          {/* <Route path="okrs/:id" element={<KeyResultPage />}></Route> */}
          {/* <Route path="caps/new" element={<CapsCreatePage />}></Route> */}
          {/* <Route path="caps/new/long-term" element={<LongTermPage />}></Route> */}
          {/* <Route path="caps/new/short-term" element={<ShortTermPage />}></Route> */}
          {/* <Route path="caps/new/zrprepare" element={<ZrpreparePage />}></Route> */}
          {/* <Route path="caps/list" element={<CapsListPage />}></Route> */}
          {/* <Route path="projects" element={<ProjectsPage />} /> */}
          {/* <Route path="okrs" element={<KeyResultsPage />} /> */}
          {/* <Route path="okrs/:id" element={<KeyResultPage />} /> */}
          <Route path="careerpath" element={<CareerPathPage />}></Route>
          <Route path="employees" element={<EmployeesPage />}></Route>
          <Route path="/my-dossier" element={<DossierPage />} />
          <Route path="/my-dossier/:employeeId" element={canAccessOptions? <DossierPage /> : <Navigate to="/" />} />
          {/* <Route path="academy" element={<AcademyPage />}></Route> */}
          {/* <Route
            path="academy/courses/:id"
            element={<ViewCoursePage />}
          ></Route> */}
          {/* <Route path="academy/courses/new" element={<NewCoursePage />}></Route>
          <Route
            path="academy/courses/:id/edit"
            element={<EditCoursePage />}
          ></Route> */}
          <Route path="generaldossier" element={
            canAccessOptions? <DossierGeneralPage /> : <Navigate to="/" />
          }></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

/**
 *
 * @param props
 * @returns
 */
function ThemedApp(): JSX.Element {
  const { isLoaded, token, updateUserWithSDKInfo } = useAuth();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const initSDK = async () => {
      try {
        SDK.init({
          accessToken: token ?? "",
          logger: console,
          logging: true,
          clientOptions: {
            baseURL: process.env.REACT_APP_API_URL,
          },
        });
        await updateUserWithSDKInfo();
      } catch (err) {
        console.error("Error on init SDK", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoaded) {
      initSDK();
    } else {
      setIsLoading(true);
    }
  }, [isLoaded, token, updateUserWithSDKInfo]);

  React.useEffect(() => {
    if (SDK.isSDKInitialized()) {
      SDK.updateToken(token ?? null);
    }
  }, [token]);

  const children = isLoading ? <LoadingPage /> : <Shell />;

  return <DynamicThemeProvider>{children}</DynamicThemeProvider>;
}

export default function App() {
  return (
    <AuthProvider provider="keycloak">
      <EmployeeProvider>
        <I18nextProvider i18n={i18n}>
          <ThemedApp />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </I18nextProvider>
      </EmployeeProvider>
    </AuthProvider>
  );
}
