import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import LevelAccordion from "./components/LevelAccordion";
import CareerPathFiltersBar from "../../components/CareerPathFiltersBar";
import CriteriaForm from "./components/CriteriaForm";
import CriteriaCard from "./components/CriteriaCard";
import { CareerLevel, Criteria } from "src/types/entities/CareerPath";
import { Department } from "src/types/entities/Department";
import Snackbar from "src/components/displayer/Snackbar";
import { Severity } from "src/components/displayer/Snackbar/styles";
import { SDK } from "@zrp/client";
import { useEmployee } from "src/providers";

type FormAction = "edit" | "close" | "create";

const CareerPath = () => {
  const { employee } = useEmployee();
  const [showForm, setShowForm] = useState({
    learner: false,
    executer: false,
    teacher: false,
  });
  const [formAction, setFormAction] = useState<FormAction>("close");
  const [formData, setFormData] = useState<Criteria | null>(null);

  const [departments, setDepartments] = useState<Department[]>([]);
  const [criteria, setCriteria] = useState<Criteria[]>([]);

  const [filterDepartmentId, setFilterDepartmentId] = useState<number | null>(
    null
  );

  const [filterCriteriaActive, setFilterCriteriaActive] =
    useState<string>("Ativos");
  const [filteredCriteriaDescription, setFilteredCriteriaDescription] =
    useState<string>("");
  const [searchInputWasCleared, setSearchInputWasCleared] = useState(false);

  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "success" as Severity,
  });

  const filteredCriteria = useMemo(
    () =>
      criteria
        .filter(i => i.department.id === filterDepartmentId)
        .filter(c =>
          { if(
            filterCriteriaActive === "Todos"
          ) return true;
            return filterCriteriaActive === "Ativos" ? c.active : !c.active
          }
        ),
    [criteria, filterCriteriaActive, filterDepartmentId]
  );

  const handleSelect =
    (
      action: FormAction,
      level: CareerLevel,
      status: boolean,
      criteriaData?: any
    ) =>
    () => {
      const entries = Object.values(CareerLevel).map(key => [key, false]);
      const resetData = Object.fromEntries(entries);
      setFormData(action === "edit" ? criteriaData : {});
      setFormAction(action);
      setShowForm({ ...resetData, [level]: status });
    };

  const handleActivateTopic = async (
    criterion: Criteria,
    level: string,
    departmentId: number | null
  ) => {
    const criteriaData = {
      ...criterion,
      active: !criterion.active,
      level,
      departmentId,
    };
    const newCriterion = await SDK.careerPathCriteria.patchByCriteriaData(criteriaData)
    setCriteria(oldCriteria =>
      oldCriteria.map(c => {
        if (c.id !== newCriterion.id) return c;
        else return newCriterion;
      })
    );
  };

  const getData = useCallback(async () => {
    const departments = await SDK.department.all();
    const criteria = await SDK.careerPathCriteria.all();
    setDepartments(departments);

    let defaultDepartment = departments.find((i:Department) => i.name === employee?.departament);
    if (!defaultDepartment) defaultDepartment = departments.find((i:Department) => i.name === "Desenvolvimento");
    if (!defaultDepartment) [defaultDepartment] = departments;
    setCriteria(criteria);
    setFilterDepartmentId(defaultDepartment?.id);
  }, [employee?.departament]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      <Grid container spacing={{ xl: 5, xs: 0 }} justifyContent="center">
        <Grid container spacing={3} xl={10}>
          <Grid container py={4} mb={2} justifyContent="space-between">
            <CareerPathFiltersBar
              departments={departments}
              handleDepartmentFilter={id => setFilterDepartmentId(Number(id))}
              selectedDepartment={
                filterDepartmentId ? String(filterDepartmentId) : ""
              }
              handleCriteriaStatus={criteriaStatus =>
                setFilterCriteriaActive(criteriaStatus)
              }
              selectedCriteriaActiveStatus={filterCriteriaActive}
              handleTopicDescriptionFilter={() => {}}
              handleFilterCriteriaDescription={setFilteredCriteriaDescription}
              setSearchInputWasCleared={setSearchInputWasCleared}
              filteredCriteriaDescription={filteredCriteriaDescription}
            ></CareerPathFiltersBar>
          </Grid>
          {Object.values(CareerLevel).map(level => (
            <LevelAccordions
              careerLevel={level}
              {...{
                filteredCriteria,
                filteredCriteriaDescription,
                filterCriteriaActive,
                showForm,
                handleSelect,
                formAction,
                formData,
                filterDepartmentId,
                setCriteria,
                setSnackbarData,
                handleActivateTopic,
                searchInputWasCleared,
                setSearchInputWasCleared,
              }}
            />
          ))}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarData.open}
        severity={snackbarData.severity}
        message={snackbarData.message}
        handleClose={() => setSnackbarData({ ...snackbarData, open: false })}
      />
    </React.Fragment>
  );
};

const LevelAccordions = (props: any) => {
  const {
    careerLevel,
    filteredCriteria,
    filteredCriteriaDescription,
    showForm,
    handleSelect,
    formAction,
    formData,
    filterDepartmentId,
    setCriteria,
    setSnackbarData,
    handleActivateTopic,
    searchInputWasCleared,
    setSearchInputWasCleared,
  } = props;

  const [open, setOpen] = useState(false);
  const [openedCriterias, setOpenedCriterias] = useState<{
    [key: number]: boolean;
  }>({});

  const handleToggleOpenCriterias = (criteriaId: number, isOpen: boolean) => {
    setOpenedCriterias(s => ({ ...s, [criteriaId]: isOpen }));
  };

  const criteriaLevel = useMemo(
    () =>
      filteredCriteria
        .filter((c: any) => c.level === careerLevel)
        .filter((c: any) =>
          c.name.toLowerCase().includes(filteredCriteriaDescription.toLowerCase())
        ),
    [careerLevel, filteredCriteria, filteredCriteriaDescription]
  );

  const shouldOpenOnSearch = useMemo(
    () => criteriaLevel.length > 0 && filteredCriteriaDescription !== "" && searchInputWasCleared === false,
    [filteredCriteriaDescription, criteriaLevel, searchInputWasCleared]
  );

  useEffect(() => {
    if(searchInputWasCleared) {
      setSearchInputWasCleared(false)
      setOpen(false)
    }
    if (open) return;
    if (showForm[careerLevel] || shouldOpenOnSearch)
    setOpen(true)
  }, [careerLevel, open, searchInputWasCleared, setSearchInputWasCleared, shouldOpenOnSearch, showForm])
  
  
  return (
    <LevelAccordion
      title={careerLevel}
      handleNewTopic={handleSelect("create", careerLevel, true)}
      expanded={open}
      handleChange={(e:any) => {e.stopPropagation();setOpen(s => !s)}}
    >
      <>
        {showForm[careerLevel] && (
          <CriteriaForm
            handleClose={handleSelect("close", careerLevel, false)}
            action={formAction}
            formDataCriteria={formData}
            level={careerLevel}
            departmentId={filterDepartmentId || 0}
            handleSubmitCb={c => {
              setCriteria((oldCriterias: Criteria[]) => {
                if (formAction === "create") return oldCriterias.concat(c);
                else
                  return oldCriterias.map(oldC => {
                    if (oldC.id !== c.id) return oldC;
                    else return c;
                  });
              });
              setOpenedCriterias(s => ({ ...s, [c.id]: true }));
            }}
            handleSnackbarData={setSnackbarData}
          />
        )}
        {!showForm[careerLevel] && criteriaLevel.length
          ? criteriaLevel.map((c: any) => (
              <CriteriaCard
                handleEditTopic={handleSelect("edit", careerLevel, true, c)}
                handleActivateTopic={() =>
                  handleActivateTopic(c, careerLevel, filterDepartmentId)
                }
                criteria={c}
                expanded={!!openedCriterias[c.id]}
                handleChange={isOpen => handleToggleOpenCriterias(c.id, isOpen)}
              />
            ))
          : !showForm[careerLevel] && (
              <div>
                <span>Não há critérios</span>
              </div>
            )}
      </>
    </LevelAccordion>
  );
};

export default CareerPath;
