/* eslint-disable no-unused-vars */
import styled from "styled-components";

export type Severity = "danger" | "success";

type Props = {
  severity: Severity;
};

const chooseBgColor = (theme: any, severity: Severity) => {
  const colors = {
    danger: theme.palette.error.main,
    success: theme.palette.success.main,
  };

  return colors[severity];
};

export const Wrapper = styled.div<Props>`
  background-color: ${props => chooseBgColor(props.theme, props.severity)};
  width: 100%;
  border-radius: 10px;
`;

export const MessageBox = styled.div`
  min-width: 500px;
  padding: 15px 30px;
  display: grid;
  grid-template-columns: 30px 1fr;
`;
