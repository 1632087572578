import React, { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import EmployeeCard from "./components/EmployeeCard";
import EmployeeFiltersBar from "./components/EmployeeFiltersBar";
import { SDK, Employee } from "@zrp/client";

export default function Employees() {
  const [employees, setEmployees] = React.useState<Employee[]>([]);
  const [filters, setFilters] = React.useState<any>({});
  const [selectedPdcLevel, setSelectedPdcLevel] = React.useState("");
  const [selectedDepartment, setSelectedDepartment] = React.useState("");
  const [searchedName, setSearchedName] = React.useState("");
  const [selectedSortingMethod, setSelectedSortingMethod] = React.useState("");

  const displayedEmployees = employees
    .filter(applyFilters)
    .sort(getSortingMethod());

  async function getEmployees() {
    let employeesData = await SDK.employees.all();
    const filteredEmployees = employeesData.filter((employee: Employee) => {
      return employee.dismissalAt === null;
    });
    setEmployees(filteredEmployees);
  }

  React.useEffect(() => {
    getEmployees();
  }, []);

  function handleNameFilter(event: ChangeEvent<HTMLInputElement>) {
    setSearchedName(event.target.value);
  }

  function handlePdcLevelFilter(event: ChangeEvent<HTMLInputElement>) {
    setSelectedPdcLevel(event.target.value);
  }

  function handleDepartmentFilter(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDepartment(event.target.value);
  }

  function handleEmployeesSorting(event: ChangeEvent<HTMLInputElement>) {
    setSelectedSortingMethod(event.target.value);
  }

  function applyFilters(employee: Employee) {
    if (
      selectedPdcLevel &&
      employee.pdcLevel?.toLowerCase() !== selectedPdcLevel.toLowerCase()
    ) {
      return false;
    }

    if (selectedDepartment && employee.departament !== selectedDepartment) {
      return false;
    }
    if (
      searchedName &&
      !employee.name.toLowerCase().includes(searchedName.toLowerCase()) &&
      !employee.lastName.toLowerCase().includes(searchedName.toLowerCase())
    ) {
      return false;
    }
    return true;
  }

  function getSortingMethod() {
    if (!selectedSortingMethod) return;

    if (selectedSortingMethod === "ordemAlfabeticaCrescente") {
      return (employeeA: Employee, employeeB: Employee) =>
        employeeA.name >= employeeB.name ? 1 : -1;
    }
    if (selectedSortingMethod === "ordemAlfabeticaDecrescente") {
      return (employeeA: Employee, employeeB: Employee) =>
        employeeA.name >= employeeB.name ? -1 : 1;
    }
    if (selectedSortingMethod === "dataContratacaoCrescente") {
      return (employeeA: Employee, employeeB: Employee) =>
        employeeA.hiredAt >= employeeB.hiredAt ? 1 : -1;
    }
    if (selectedSortingMethod === "dataContratacaoDecrescente") {
      return (employeeA: Employee, employeeB: Employee) =>
        employeeA.hiredAt >= employeeB.hiredAt ? -1 : 1;
    }
  }

  React.useEffect(() => {
    function getFilters() {
      const departments = employees.map(
        (employee: Employee) => employee.departament
      );

      const uniqueDepartments = Array.from(new Set(departments));
      const filters = {
        departments: uniqueDepartments,
      };
      setFilters(filters);
    }
    getFilters();
  }, [employees]);

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
          <EmployeeFiltersBar
            filters={filters}
            resultCounter={displayedEmployees.length}
            handleNameFilter={handleNameFilter}
            handlePdcLevelFilter={handlePdcLevelFilter}
            selectedPdcLevel={selectedPdcLevel}
            handleDepartmentFilter={handleDepartmentFilter}
            selectedDepartment={selectedDepartment}
            handleEmployeesSorting={handleEmployeesSorting}
          />
          <Grid container p={4} spacing={4} justifyContent="flex-start" >
          {displayedEmployees.map(employee => {
            return (
              <Grid key={employee.id} item xl={4} sm={12} md={6}>
                <EmployeeCard employee={employee}></EmployeeCard>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
