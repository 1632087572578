import { Button, Divider, Grid, Stack } from "@mui/material";
import { Analysis, SDK } from "@zrp/client";
import { AnalysisCommentary } from "@zrp/client/src/models";
import { Fragment, useEffect, useState, Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import CommentaryForm from "./AnalysisHistoryComponents/CommentaryForm";
import LastStatusUpdateInfo from "./AnalysisHistoryComponents/LastStatusUpdateInfo";
import AnalysisHistoryList from "./AnalysisHistoryComponents/AnalysisFullHistory"
import ConfirmDialog from "src/components/ConfirmDialog";
import { useEmployee } from "src/providers";

export default function AnalysisHistory(props: {
  analysis: Analysis;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>
}): JSX.Element {
  let {
    analysis,
    editMode,
    setEditMode,
  } = props;
  const { canAccessOptions } = useEmployee();
  const [comment, setComment] = useState<AnalysisCommentary>();
  const [commentary, setCommentary] = useState<AnalysisCommentary>();
  const [commentaries, setCommentaries] = useState<AnalysisCommentary[]>(analysis.careerAnalysisCommentaries || [] as AnalysisCommentary[]);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, message: '', onConfirm: () => {} })
  
  async function deleteCommentary(commentaryId: number | undefined) {
    try {
        if (commentaryId && commentaries.length) {
          await SDK.analysis.commentary.deleteById(commentaryId);
          const removeCommentById = (commentaryId: number) => {
            return commentaries.filter(comment => comment.id !== commentaryId);
          };
          const updatedComments = removeCommentById(commentaryId);
          setCommentaries(updatedComments);
      }
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${error.message}`);
    } finally {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false
      })
    }
  }

  async function createCommentaries(
    comment: AnalysisCommentary | undefined
  ) {
    try {
      if (analysis.id && comment){
        const response = await SDK.analysis.commentary.createByAnalysisId(
          comment,
          analysis.id
        );
        setCommentary(response);
        setEditMode(false)
      }
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${error.message}`);
    }
  }

  const deleteAlert = (commentaryId: number | undefined) => {
    setConfirmDialog({
      isOpen: true,
      message: 'Você tem certeza que deseja apagar este comentário?',
      onConfirm: () => { deleteCommentary(commentaryId) }
    })
  }

  useEffect(() => {
    if(commentary) {
      setCommentaries(prevItems => [...prevItems, commentary]);
    }
  }, [commentary]);

  const disableConfirmButton = () => {
    return !comment || !comment.comment || comment.comment.trim() === '';
  };

  return (
    <Fragment>
      <LastStatusUpdateInfo analysis={analysis} />
      <AnalysisHistoryList analysis={analysis} commentaries={commentaries} deleteAlert={(commentaryId) => deleteAlert(commentaryId)} />
        { editMode ?
        <Grid>
          <CommentaryForm 
            analysisCommentary={commentary}
            onChangeComment={(newText) => {
              setComment(newText); 
            }} 
          /> 
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                mt: "20px",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "7px 30px",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  marginRight: "20px",
                }}
                onClick={() => {setEditMode(!editMode)}}
              >
                Cancelar
              </Button>
              <Button
                disabled={disableConfirmButton()}
                sx={{
                  backgroundColor: "#6799CC",
                  color: "white",
                  padding: "7px 30px",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
                onClick={() => {
                  createCommentaries(comment)
                }}
              >
                Confirmar
              </Button>
            </Grid>
        </Grid>
          :
          <>
          <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            mt: "20px",
          }}
        >
          {canAccessOptions ?
            <Button
              sx={{
                backgroundColor: "#6799CC",
                color: "white",
                padding: "7px 30px",
                borderRadius: "40px",
                textTransform: "capitalize",
                fontWeight: "600",
                marginRight: "20px",
              }}
              onClick={() => {setEditMode(!editMode)}}
                  >
                Editar Análise
            </Button> 
            :
            <div></div>
          }
        </Grid> 
        </>
        }
      <Stack spacing={2}>
        <Grid>
        </Grid>
      </Stack>
      <Divider sx={{ mt: 3 }} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Fragment>
  );
}
