import { Box, FormControl, Select, MenuItem, SelectChangeEvent, Grid} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { CareerAnalysisStatus } from "./../../../interfaces"
import ConfirmDialog from "src/components/ConfirmDialog";

enum StatusTranslation {
  "NotCompleted" = "Não cumprido",
  "Completed" = "Concluído",
  "InProgress" = "Em progresso",
  "NotValidated" = "Não validado"
}

export default function EditAnalysisStatusChip(props: 
  { 
    status: string
    onStatusChange: (statusValue: string) => void;
  }): JSX.Element {
  const { status, onStatusChange } = props
  const [statusValue, setStatus] = useState(status)
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, message: '', onConfirm: () => {} })

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value)
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
  };

  const alertStatusChange = (event: SelectChangeEvent) => {
    setConfirmDialog({
      isOpen: true,
      message: 'Você tem certeza que deseja alterar o status dessa análise?',
      onConfirm: () => { handleChange(event); }
    })
  }

  useEffect(() => {
    if(status !== statusValue) {
      onStatusChange(statusValue);
    }
  },[statusValue, status, onStatusChange])

  return (
    <Fragment>
      <Grid display="flex" flexDirection="row" sx={{ margin: "10px 0px" }}>
      <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          value={statusValue}
          onChange={alertStatusChange}
          displayEmpty
          sx={{ 
            color: "primary",
            border: '1px',
            borderRadius: "30px",
          }}
        >
          { Object.keys(CareerAnalysisStatus).map((status: string) => (
            <MenuItem value={status} key={status}>{StatusTranslation[status as keyof typeof StatusTranslation]}</MenuItem>
          )) }
        </Select>
      </FormControl>
    </Box>
    </Grid>
    <ConfirmDialog
      confirmDialog={confirmDialog}
      setConfirmDialog={setConfirmDialog}
    />
    </Fragment>
  );
}
