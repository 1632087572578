import styled from "styled-components";
import { Card } from "@mui/material";
import MuiButton from "@mui/material/Button";

export const Form = styled(Card)`
  width: 100%;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  svg {
    fill: ${props => props.theme.palette.info.main};
  }

  span {
    color: ${props => props.theme.palette.info.main};
    font-weight: bold;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.text.primary};
  padding: 20px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const TextButton = styled(MuiButton)`
  border-radius: 100px;
  background-color: transparent;
  color: ${props => props.theme.palette.text.primary};
  text-transform: none;
  width: 150px;
  font-weight: bold;
`;

export const Button = styled(TextButton)`
  background-color: ${props => props.theme.palette.info.main};
`;

export const CriteriaCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 20px;
  border-top: 2px solid ${props => props.theme.palette.background.default};
`;

export const CriteriaCardItemTitle = styled.div`
  display: flex;
  column-gap: 10px;

  svg {
    fill: ${props => props.theme.palette.info.main};
  }

  span {
    color: ${props => props.theme.palette.info.main};
    font-weight: bold;
  }
`;

export const CriteriaCardItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
