import {
  // DashboardOutlined,
  // Abc,
  // AutoGraph,
  FormatListBulleted,
  Group,
  Work,
  LibraryBooks,
} from "@mui/icons-material";
// import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
// import ListIcon from "@mui/icons-material/List";
// import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
// import MenuBookIcon from '@mui/icons-material/MenuBook';
// import StarsIcon from "@mui/icons-material/Stars";

import { JSXElementConstructor } from "react";

import { NavigateOptions } from "react-router";

interface MenuItem {
  title: string;
  to: string;
  navigateOptions?: NavigateOptions;
  group?: string;
  icon?: JSXElementConstructor<any>;
  collapse?: MenuItem[];
}

export const MENU_ITEMS: MenuItem[] = [
//  {
//    title: "Dashboard",
//    icon: DashboardOutlined,
//    to: "/",
//  },
  {
    title: "Meu Dossiê",
    icon: FormatListBulleted,
    to: "/my-dossier",
  },
  {
    title: "Plano de Carreira",
    icon: Work,
    to: "/careerpath",
  },{
    title: "Dossiê Geral",
    icon: LibraryBooks,
    to: "/generaldossier"
  },
  // {
  //   title: "Objetivos",
  //   icon: Abc,
  //   to: "/objectives",
  //   group: "Estratégia",
  // },
  // {
  //   title: "Resultados",
  //   icon: AutoGraph,
  //   to: "/okrs",
  //   group: "Estratégia",
  // },
  // {
  //   title: "CAP",
  //   icon: PeopleOutlineIcon,
  //   to: "",
  //   collapse: [
  //     {
  //       title: "Cadastrar CAP",
  //       icon: DriveFileRenameOutlineIcon,
  //       to: "/caps/new",
  //     },
  //     {
  //       title: "Listagem de CAPS",
  //       icon: ListIcon,
  //       to: "/caps/list",
  //     },
  //     {
  //       title: "ZRPoints",
  //       icon: StarsIcon,
  //       to: "/caps/zrpoints",
  //     },
  //   ],
  // },
  // {
  //   title: "Projetos",
  //   icon: AutoGraph,
  //   to: "/projects",
  //   group: "Projetos",

  // },
  {
    title: "ZRPenh@s",
    icon: Group,
    to: "/employees",
  },
  // {
  //   title: "ZRP Academy",
  //   icon: MenuBookIcon,
  //   to: "/academy",
  // },
];
