import * as React from "react";
import {
  Avatar,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Link,
} from "@mui/material";

import LogoutButton from "./LogoutButton";
import { useAuth } from "../providers";
import {
  LockOutlined,
  EmailOutlined,
  Event,
  LanguageOutlined,
  AccountBoxOutlined,
} from "@mui/icons-material";

import moment from "moment";

const Profile = () => {
  const { user } = useAuth();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item>
          {user?.picture ? (
            <Avatar sx={{ width: 128, height: 128 }} src={user?.picture} />
          ) : (
            <Avatar sx={{ width: 128, height: 128 }}>{user?.initials}</Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
            {user?.name}
          </Typography>
          <Typography>{user?.email}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />
      <Typography mt={2}>Configurações da Conta</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <LockOutlined></LockOutlined>
          </ListItemIcon>
          <ListItemText
            primary="TOPT"
            secondary={user?.topt ? "Habilitado" : "Desativado"}
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailOutlined></EmailOutlined>
          </ListItemIcon>
          <ListItemText
            primary="E-mail Verificado"
            secondary={user?.emailVerified ? "Verificado" : "Pendente"}
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LanguageOutlined></LanguageOutlined>
          </ListItemIcon>
          <ListItemText
            primary="Idioma"
            secondary={user?.locale}
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Event></Event>
          </ListItemIcon>
          <ListItemText
            primary="Membro desde"
            secondary={moment(user?.createdAt ?? "").fromNow()}
          ></ListItemText>
        </ListItem>
      </List>

      <Button
        component={Link}
        href="https://auth.zrp.com.br/auth/realms/ZRP/account/#/"
        target="_blank"
        sx={{ textTransform: "none", mt: 4 }}
        variant="outlined"
        color="primary"
        size="large"
        startIcon={<AccountBoxOutlined />}
      >
        <Typography variant="h6">Gerenciar conta</Typography>
      </Button>
      <div></div>
      <LogoutButton sx={{ mt: 2 }}></LogoutButton>
    </React.Fragment>
  );
};

export default Profile;
