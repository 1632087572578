import {
  Avatar,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { formatUTCDate } from "src/utils/date";

import React from "react";
import { Employee } from "@zrp/client";
import { Cake, Public, Work } from "@mui/icons-material";
import { CardStyled } from "./EmployeeCardStyle";

export default function EmployeeCard(props: { employee: Employee }) {
  const { employee } = props;
  return (
    <React.Fragment>
      <CardStyled>
        <CardContent>
          <Grid container flexDirection="column">
            <Grid
              container
              flexDirection={{ xs: "column", xl: "row" }}
              alignItems="center"
            >
              <Avatar
                sx={{ width: 56, height: 56, bgcolor: "#F9F9F9" }}
                src={employee?.avatarImagePath || ""}

              ></Avatar>
              <Grid
                p={2}
                display={{ xs: "flex", xl: "block" }}
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h5">
                  {employee.name} {employee.lastName}
                </Typography>
                <Typography sx={{ opacity: "0.3" }} variant="body2">
                  {employee.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              m="30px 0"
              gap={1}
              justifyContent={{ xs: "center", xl: "start" }}
            >
              <Chip
                sx={{
                  backgroundColor: "rgba(102, 152, 203, 0.3)",
                  color: "#5884C3",
                }}
                label={
                  <Typography variant="caption">
                    {employee.departament}
                  </Typography>
                }
              ></Chip>
              {employee.pdcLevel != null && (
                <Chip
                  sx={{
                    backgroundColor: "rgba(121, 175, 101, 0.3)",
                    color: "#79AF65",
                  }}
                  label={
                    <Typography variant="caption">{employee.pdcLevel}</Typography>
                  }
                ></Chip>
              )}
            </Grid>
            <Grid sx={{ opacity: 0.3 }}>
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", xl: "start" },
                  marginTop: "5px",
                }}
              >
                <Public sx={{ height: 16, width: 16 }}></Public>
                &nbsp;&nbsp;<b>Localidade: &nbsp;</b>
                {employee.state && employee.city
                  ? `${employee.state}, ${employee.city}`
                  : "Não informado"}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", xl: "start" },
                  marginTop: "5px",
                }}
              >
                <Work sx={{ height: 16, width: 16 }}></Work>
                &nbsp;&nbsp;<b>Data de contratação:&nbsp;</b>
                {formatUTCDate(new Date(employee.hiredAt))}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", xl: "start" },
                  marginTop: "5px",
                }}
              >
                <Cake sx={{ height: 16, width: 16 }}></Cake>
                &nbsp;&nbsp;<b>Data de nascimento:&nbsp;</b>
                {employee.birthDate
                  ? formatUTCDate(new Date(employee.birthDate))
                  : "Não informado"}
              </Typography>
            </Grid>
            <Grid
              display={{ xs: "flex", xl: "block" }}
              justifyContent={{ xs: "center", xl: "start" }}
            >

            </Grid>
          </Grid>
        </CardContent>
      </CardStyled>
    </React.Fragment>
  );
}
