import { Axios } from 'axios';
import { Progress } from 'src/models/Progress';
import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class ProgressService extends BaseService<Progress> {

  getByEmployeeId: (id: number) => Promise<Progress>;

  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/career-path/progress';
    this.getByEmployeeId = async function (id: number): Promise<Progress> {
      const response = await this.http.get(`${this.basePath}/employee/${id}`);
      const { data } = JSON.parse(response.data);
      return data;
    };
  }
}
