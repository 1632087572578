import {
  Grid, Switch, TextField, Typography
} from "@mui/material";
import { AnalysisCommentary } from "@zrp/client/src/models";
import { useEffect, useState } from "react";


export default function CommentaryForm(props: {
  analysisCommentary?: AnalysisCommentary;
  onChangeComment: (text: any) => void;
}): 
  JSX.Element {
  const { analysisCommentary, onChangeComment } = props

  const [restrictedComment, setRestrictedComment] = useState<boolean>(false);
  const [analysisComment, setAnalysisComment] = useState<AnalysisCommentary>(analysisCommentary || {} as AnalysisCommentary);

  function newCommentary(event: any) {
    setAnalysisComment({comment: event, restricted: restrictedComment})
  }

  useEffect(() => {
    onChangeComment(analysisComment)
  }, [analysisComment, onChangeComment]);



  return (
    <Grid container flexDirection="column">
      <Grid display="flex" alignItems="center">
        <Typography color="#5B5B64">
          <b>Restringir comentário (RH E CT)</b>
        </Typography>
        <Switch onChange={() => {setRestrictedComment(!restrictedComment)}} />
      </Grid>
      <Grid display="flex" flexDirection="row" item xs={12}>
        <TextField
          multiline
          rows={6}
          defaultValue={""}
          fullWidth
          onChange={(event) => {newCommentary(event.target.value)}}
        />
      </Grid>
    </Grid>
  )
}