import { Stack, Grid, Typography, Divider } from "@mui/material";
import { Fragment } from "react";

export default function AnalysisHeader(): JSX.Element {

  return (
    <Fragment>
      <Stack gap={1}>
        <Grid
          display="flex"
          gap={1}
          mb={1}
          justifyContent="space-between"
          alignItems="center"
        >
        </Grid>
        <Typography variant="overline">
          <b>Registro de Atividade</b>
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Stack>
    </Fragment>
  );
}
