import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";
import * as S from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "30px",
  borderRadius: "36px",
  p: 4,
};

type Props = {
  open: boolean;
  handleClose: () => void;
  handleCloseForm: () => void;
};

const CloseCriteriaFormModal = (props: Props) => {
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Card sx={style}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Deseja mesmo cancelar esse novo tópico?
        </Typography>
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
          Ao clicar "SIM", suas alterações serão perdidas e o tópico será
          cancelado
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <S.TextButton onClick={props.handleClose}>Não</S.TextButton>
          <S.Button onClick={props.handleCloseForm}>Sim</S.Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default CloseCriteriaFormModal;
