import { Axios } from 'axios';
import { Criteria, CriteriaPerLevel } from 'src/models/CareerPathCriteria';
import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class CriteriaService extends BaseService<Criteria> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/career-path/criteria';
  }
  patchByCriteriaData = async (body: Criteria): Promise<Criteria> => {
      const response = await this.update(body);
      return response as Criteria;
    };
  getByEmployeeId = async  (id: string | number): Promise<CriteriaPerLevel> => {
    const response = await this.http.get(`${this.basePath}/employee/${id}`)
    const { data } = JSON.parse(response.data);
      return data as CriteriaPerLevel;
  }
}
