import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import CareerPathAccordion from "src/components/CareerPathAccordion";
import { useEmployee } from "src/providers";

type Props = {
  title: string;
  children: JSX.Element;
  handleNewTopic: (e: any) => void;
  expanded?: boolean;
  handleChange?: any;
};
export default function LevelAccordion(props: PropsWithChildren<Props>) {
  const { canAccessOptions } = useEmployee();

  return (
    <CareerPathAccordion
      title={props.title}
      expanded={props.expanded}
      handleChange={props.handleChange}
      HeaderComponent={() => (
        <Typography
          variant="subtitle1"
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: 999,
            columnGap: "10px",
          }}
          onClick={props.handleNewTopic}
        >
          {canAccessOptions && <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <AddIcon sx={{ fontSize: 40 }} />
            <span>Criar novo tópico</span>
          </Box>}
        </Typography>
      )}
    >
      {props.children}
    </CareerPathAccordion>
  );
}
