import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: "transparent",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderBottom: "3px solid #79AF65",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function DossierAccordion(props: {
  title: string;
  progress?: number;
  children: JSX.Element;
}) {
  const { title, progress, children } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      sx={{ width: "100%" }}
      expanded={expanded === "learner"}
      onChange={handleChange("learner")}
    >
      <AccordionSummary>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid xs={ 6 }>
            <Typography variant="h5">
              <b>{ title.charAt(0).toUpperCase() + title.slice(1) }</b>
            </Typography>
          </Grid>
          <Grid xs={ 6 } sx={ { display: "flex", alignItems: "center" } }>
            { progress !== null || progress !== undefined ?
            <LinearProgress
              variant="determinate"
              color="info"
              value={ progress }
              sx={ {
                width: "80%",
                height: 9.6,
                borderRadius: 5,
                marginRight: "17px"
              } }
            /> : <div></div>
            }
            { progress !== null || progress !== undefined ?
            <Typography variant="h6" align="right">{ progress }%
            </Typography> : <div></div>
            }
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex" }}>
        { children }
      </AccordionDetails>
    </Accordion>
  );
}
