import React from "react";
import { Card } from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import CloudIcon from "@mui/icons-material/Cloud";
import { Criteria } from "src/types/entities/CareerPath";
import * as S from "./styles";
import CriteriaAccordion from "./CriteriaAccordion";

const CriteriaCard = (props: {
  criteria: Criteria;
  handleEditTopic: () => void;
  handleActivateTopic: () => void;
  handleChange: (value: boolean) => any;
  expanded: boolean;
}) => {
  const { name, objective, expectation } = props.criteria;
  const { handleEditTopic, handleActivateTopic } = props;
  return (
    <Card sx={{ borderRadius: "20px", width: "100%" }}>
      <CriteriaAccordion
        title={name}
        titleTypography="subtitle1"
        noDivider
        handleOptionsEdit={handleEditTopic}
        handleActivateTopic={handleActivateTopic}
        isTopicActive={props.criteria.active}
        expanded={props.expanded}
        handleChange={props.handleChange}
      >
        <S.CriteriaCardWrapper>
          <S.CriteriaCardItem>
            <S.CriteriaCardItemTitle>
              <GpsFixedIcon />
              <span>Objetivo:</span>
            </S.CriteriaCardItemTitle>
            <span>{objective}</span>
          </S.CriteriaCardItem>

          <S.CriteriaCardItem>
            <S.CriteriaCardItemTitle>
              <CloudIcon />
              <span>Expectativa:</span>
            </S.CriteriaCardItemTitle>
            <span>{expectation}</span>
          </S.CriteriaCardItem>
        </S.CriteriaCardWrapper>
      </CriteriaAccordion>
    </Card>
  );
};

export default CriteriaCard;
