import { Axios } from 'axios';
import { KeyResult } from '../models/KeyResult';

import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class KeyResultsService extends BaseService<KeyResult> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/results';
  }
}
