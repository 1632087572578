import { Grid, CircularProgress } from "@mui/material";

export default function LoadingPage() {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        position: "absolute",
        left: "0",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
