/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect } from "react";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { Navigate, useLocation } from "react-router";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import { env } from "../config";
import { Auth0Provider, useAuth0, User as Auth0User } from "@auth0/auth0-react";
import { SDK } from "@zrp/client";
import { NullOrUndefined } from "../types/Utils";

const keycloakClient = new Keycloak(env.keycloak);

interface User {
  name: NullOrUndefined<string>;
  email: NullOrUndefined<string>;
  picture: NullOrUndefined<string>;
  topt: NullOrUndefined<boolean>;
  emailVerified: NullOrUndefined<boolean>;
  createdAt: NullOrUndefined<string>;
  initials: NullOrUndefined<string>;
  locale: NullOrUndefined<string>;
  departament: NullOrUndefined<string>;
}

interface LoginParams {
  [k: string]: any;
}

interface LogoutParams {
  returnTo?: string;
}

export type AuthProviderName = "keycloak" | "auth0";

export interface AuthContextType {
  initialized: boolean;
  error: NullOrUndefined<Error | any>;
  user: NullOrUndefined<User>;
  updateUserWithSDKInfo: () => Promise<void>;
  token: NullOrUndefined<string>;
  authenticated: NullOrUndefined<boolean>;
  isLoaded: boolean;
  login: (params?: LoginParams) => Promise<void>;
  logout: (params?: LogoutParams) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const fromAuth0User = (
  user: NullOrUndefined<Auth0User>
): NullOrUndefined<User> => {
  return user
    ? ({
        email: user?.email,
        emailVerified: user?.email_verified,
        createdAt: null,
        name: user.name,
        picture: user.picture,
        topt: false,
        initials:
          (user.given_name?.at(0)?.toLocaleUpperCase() ?? "") +
          (user.family_name?.at(0)?.toLocaleUpperCase() ?? ""),
        locale: user.locale ?? "pt-BR",
      } as User)
    : null;
};

const fromKeycloakUser = (
  user: NullOrUndefined<KeycloakProfile & { attributes?: any }>
): NullOrUndefined<User> => {
  return user
    ? ({
        name: user.firstName + " " + user.lastName,
        email: user.email ?? "",
        createdAt: user.createdTimestamp?.toString() ?? "",
        picture: user.attributes?.picture ?? "",
        topt: user.totp ?? false,
        emailVerified: user.emailVerified ?? false,
        initials:
          (user.firstName?.at(0)?.toLocaleUpperCase() ?? "") +
          (user.lastName?.at(0)?.toLocaleUpperCase() ?? ""),
        locale: user.attributes?.locale ?? "pt-BR",
      } as User)
    : null;
};

export function AuthProvider({
  children,
  ...props
}: {
  children: React.ReactNode;
  provider?: AuthProviderName;
}) {
  const provider = props.provider ?? null;
  const [newToken, setNewToken] = React.useState<string | undefined>('');

  if (provider === "auth0") {
    return (
      <Auth0Provider
        clientId={env.auth0.clientId}
        domain={env.auth0.domain}
        audience="https://zrp-api"
      >
        <_Auth0Provider>{children}</_Auth0Provider>
      </Auth0Provider>
    );
  } else if (provider === "keycloak") {
    return (
      <ReactKeycloakProvider
        authClient={keycloakClient}
        onTokens={({ token }) => {
          setNewToken(token)
        }}
        initOptions={{ onLoad: "check-sso", checkLoginIframe: false }}
      >
        <_KeycloakProvider token={newToken}>{children}</_KeycloakProvider>
      </ReactKeycloakProvider>
    );
  } else {
    return <>{children}</>;
  }
}

function _Auth0Provider({ children }: { children: React.ReactNode }) {
  const {
    loginWithPopup: auth0Login,
    logout: auth0Logout,
    user: auth0User,
    isLoading,
    error: auth0Error,
    getAccessTokenSilently,
  } = useAuth0();

  const getToken = async () => {
    const t = await getAccessTokenSilently({
      audience: "https://zrp-api",
      scope: "*",
    });
    setToken(t);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [user, setUser] = React.useState<NullOrUndefined<User>>(null);

  React.useEffect(() => {
    getToken();
    if (auth0User?.sub && !token) getToken();
  }, [getAccessTokenSilently, auth0User?.sub]);

  useEffect(() => {
    if (!user) setUser(fromAuth0User(auth0User));
  }, [user, auth0User]);

  const error = auth0Error;

  const [token, setToken] = React.useState<NullOrUndefined<string>>(null);

  const login = async (_params?: LoginParams) => {
    await auth0Login();
    await getToken();
  };

  const logout = async (params?: LogoutParams) => {
    auth0Logout({ returnTo: params?.returnTo });
  };

  const updateUserWithSDKInfo = useCallback(async () => {
    if (!user?.email) return;
    const [result] = await SDK.employees.all({ email: user?.email });
    setUser(s => ({ ...result, ...s }));
  }, [user?.email]);

  const value = {
    initialized: isLoading === false,
    authenticated: !!token,
    error,
    isLoaded,
    user,
    updateUserWithSDKInfo,
    token,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function _KeycloakProvider({ children, token }: { children: React.ReactNode, token: string|undefined }) {
  const { keycloak: instance, initialized } = useKeycloak();

  const [user, setUser] = React.useState<NullOrUndefined<User>>(null);
  const [error, setError] = React.useState<NullOrUndefined<Error | any>>(null);
  const [value, setValue] = React.useState<AuthContextType>({
    initialized: false,
    authenticated: false,
    token: undefined,
    isLoaded: false,
    error: null,
    user: null,
    updateUserWithSDKInfo: async () => {},
    login: async (_params?: LoginParams) => {},
    logout: async () => {},
  });

  React.useEffect(() => {
    const loadUser = async () => {
      let user;
      try {
        const [profile] = await Promise.all([instance.loadUserProfile()]);
        user = fromKeycloakUser(profile)
        setUser(user);
      } catch(error){
        console.error(error);
      } finally {
        const valueObject = {
          initialized: initialized && instance.authenticated != null,
          authenticated: instance.authenticated,
          token: instance.token,
          isLoaded: true,
          error,
          user: user,
          updateUserWithSDKInfo,
          login,
          logout,
        };
        setValue(valueObject);
      }
    };

    if (initialized) {
      if (instance.authenticated && instance.token) {
        loadUser();
      } else if (instance.authenticated === false) {
        setUser(null);
        const valueObject = {
          initialized: initialized && instance.authenticated != null,
          authenticated: instance.authenticated,
          token: instance.token,
          isLoaded: true,
          error,
          user: null,
          updateUserWithSDKInfo,
          login,
          logout,
        };
        setValue(valueObject);
      }
    }
    
  }, [initialized, instance.authenticated, instance.token, token]);

  const login = async (_params?: LoginParams) => {
    await instance.login({});
  };

  const logout = async (params?: LogoutParams) => {
    await instance.logout({ redirectUri: params?.returnTo });
    setUser(null);
    setError(null);
  };

  const updateUserWithSDKInfo = useCallback(async () => {
    if (!user?.email) return;
    const [result] = await SDK.employees.all({ email: user?.email });
    setUser(s => ({ ...result, ...s }));
  }, [user?.email]);


  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 *
 * @returns
 */
export function useAuth() {
  return React.useContext(AuthContext);
}

/**
 *
 * @param props
 * @returns
 */
export function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoaded, authenticated } = useAuth();
  const location = useLocation();

  if (isLoaded && !authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
