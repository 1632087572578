import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import { Employee, LevelProgress, SDK } from "@zrp/client";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function DossierGeneralCard(props: { employee: Employee }) {
  const [progress, setProgress] = useState<number | undefined>(0);
  const [lastTimeUpdatedProgress, setLastTimeUpdatedProgress] =
    useState<Date>();
  const [lastUpdatedBy, setLastUpdatedBy] =
    useState<string>();
  const navigate = useNavigate();

  const { employee } = props;

  function getFormattedDatetime(date: Date | undefined): string {
    if (date) {
      return moment(date).format('lll')
    }
    return "";
  }

  async function getLevelProgress() {
    try {
      const apiProgress = await SDK.progress.getByEmployeeId(+employee.id);
      const employeeLevelProgress = getEmployeeLevelProgress(
        apiProgress.levels,
        employee.pdcLevel?.toLowerCase() || "learner"
        );
        setProgress(employeeLevelProgress?.progress);
      } catch {
      toast.error("Ops! Houve um erro ao buscar o progresso.")
    }
  }
  async function getLastTimeUpdatedProgress() {
    if (employee.analysis.length > 0 && employee.analysis[0].updatedAt) {
      setLastTimeUpdatedProgress(new Date(employee.analysis[0].updatedAt));
      setLastUpdatedBy(employee.analysis[0].updatedBy)
    }
  }

  function getEmployeeLevelProgress(
    levels: LevelProgress[],
    employeeLevel: string
  ) {
    return levels.find(level => level.name === employeeLevel);
  }
  useEffect(() => {
    getLevelProgress();
    getLastTimeUpdatedProgress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ py: 0 }}>
      <CardContent>
        <Grid container flexDirection="column">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                sx={{ width: 96, height: 96 }}
                src={ employee.avatarImagePath } 
                alt={ employee.name }
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {employee.name} {employee.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={1} alignItems="center" spacing={2}>
            <Grid item>
              <Chip
                sx={{
                  backgroundColor: "rgba(102, 152, 203, 0.3)",
                  color: "#5884C3",
                }}
                label={
                  <Typography variant="caption">
                    {employee.departament}
                  </Typography>
                }
              ></Chip>
            </Grid>
            <Grid item>
              <Chip
                sx={{
                  backgroundColor: "rgba(175, 128, 178, 0.3)",
                  color: "#AF80B2",
                }}
                label={
                  <Typography variant="caption">{employee.pdcLevel}</Typography>
                }
              ></Chip>
            </Grid>
          </Grid>
        </Grid>
        <Grid py={3}>
          <LinearProgress
            sx={{ height: "30px", my: 1 }}
            variant="determinate"
            value={progress || 0}
          />
          <Typography>{progress || 0}% do nível concluído</Typography>
          <Typography>
            Última atualização: {getFormattedDatetime(lastTimeUpdatedProgress) || "Não houveram atualizações"}
            {"\n"}feita por {lastUpdatedBy || "Sistema"} 
          </Typography>
        </Grid>
        <Button variant="contained"
          onClick={ () => {navigate(`/my-dossier/${employee.id}`)}}
          sx={{
            backgroundColor: "#6799CC",
            color: "white",
            padding: "10px 30px",
            borderRadius: "40px",
            textTransform: "capitalize",
            fontWeight: "600"
          }}
        >
            Abrir Dossiê
        </Button>
      </CardContent>
    </Card>
  );
}
