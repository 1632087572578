import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Stack, Typography, Divider } from "@mui/material";
import { Analysis, SDK } from "@zrp/client";
import { AnalysisUpdate } from '@zrp/client/src/models';
import AnalysisStatusChip from "../../AnalysisStatusChip";
import moment from "moment";

function formatDate(date: Date): string {
  return moment(date).format('lll')
}

export default function LastStatusUpdateInfo(props: { analysis: Analysis }): JSX.Element {
  const [lastUpdate, setLastUpdate] = useState<AnalysisUpdate>();
  const [dateString, setDateString] = useState<string>();
  const { analysis } = props;

  async function getLastUpdate(analysisId: number | undefined) {
    if (analysisId){
      const res = await SDK.analysis.history.getLastUpdate(analysisId);
      setLastUpdate(res);
    }
  }
  useEffect(() => {
    try {
      getLastUpdate(analysis.id);
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${error.message}`);
    }
  }, [analysis]);

  useEffect(() => {
    if (!lastUpdate?.to?.updatedAt) return;
    const update = new Date(lastUpdate.to.updatedAt);
    setDateString(formatDate(update));
  }, [lastUpdate]);

  return (
    <Fragment>
      {
        !lastUpdate?.from || lastUpdate?.from === lastUpdate?.to?
          <div></div>
        :
        lastUpdate?.to?.updatedAt
        ? <div>
            <Typography sx={{ color: "#A4A4AD" }} variant="subtitle2">
            - Última alteração de status em {dateString}
            </Typography>
            <Stack py={2} flexDirection="row" alignItems="center" gap={2}>
                De&nbsp;
              <AnalysisStatusChip status={ lastUpdate?.from.changes.status || ""}></AnalysisStatusChip>
                para
              <AnalysisStatusChip status={ lastUpdate?.to?.changes.status || "" }></AnalysisStatusChip>
            </Stack>
            <Divider sx={{ mb: 2 }}/>
          </div>
        : <div></div>
      }
    </Fragment>
  );
}
