import { Button, Card, Grid, Typography } from "@mui/material";
import { Criteria, Employee, SDK } from "@zrp/client";
import { useState, useEffect, useRef } from "react";
import CriteriaDescription from "../CriteriaDescription";
import AnalysisHistory from "./AnalysisHistory";
import AnalysisHeader from "./AnalysisHeader";
import AnalysisStatusChip from "../AnalysisStatusChip";
import EditAnalysisStatusChip from "./AnalysisHistoryComponents/EditAnalysisStatusChip";
import { toast } from "react-toastify";
import { useEmployee } from "src/providers/EmployeeProvider";

export default function AnalysisCard(props: {
  criteria: Criteria;
  employee: Employee;
}): JSX.Element {
  const { canAccessOptions } = useEmployee();
  const { criteria, employee } = props;
  const [analysis, setAnalysis] = useState(criteria.analysis)
  const [editMode, setEditMode] = useState(false);
  const [statusValue, setStatus] = useState(analysis.status);
  const analysisRef = useRef(analysis);

  useEffect(() => {
    async function editStatus(statusValue: string) {
      const analysisStatus = { ...analysisRef.current, status: statusValue };
      try {
        const response = await SDK.analysis.status.patchStatus(analysisStatus);
        setAnalysis((prevAnalysis) => ({
          ...prevAnalysis,
          response
        }));
      } catch (error) {
        toast.error("Erro ao atualizar status.");
      }
    }
  
    if (statusValue !== analysisRef.current.status) {
      editStatus(statusValue);
    }
  }, [statusValue]);

  useEffect(() => {
    analysisRef.current = analysis;
  }, [analysis]);

  const createAnalysis = async () => {
    if (employee.job !== "Sócio"){
      const analysis = await SDK.analysis.createByCriteriaId({criteriaId: criteria.id, status: "NotValidated", employeeId: employee.id});
      setAnalysis(analysis)
    } else {
      toast.error("Ops! Não é possível criar análise para Sócio");
    }
  }

  return (
    <Card sx={{ margin: "20px 0px", borderRadius: 5 }}>
      <Grid display="flex" justifyContent="space-between" p={4}>
        <Grid xs={4}>
          { Object.keys(analysis).length === 0 ?
              <div></div>
            : editMode ?  
              <EditAnalysisStatusChip
                status={statusValue}
                onStatusChange={(statusValue) => setStatus(statusValue)}/> 
            : 
              <AnalysisStatusChip status={ statusValue } />
          }
          <CriteriaDescription criteria={criteria}></CriteriaDescription>
        </Grid>

        <Grid xs={8} px={4}>
          <AnalysisHeader/>
          { Object.keys(analysis).length !== 0 ? 
            <AnalysisHistory
              analysis={analysis}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          :
            <>
              <Typography
               variant={"h6"}
               sx={{
                 color: "grey",
                 mb:2,
               }}              
              >Sem registro vinculado </Typography>
              {canAccessOptions
                ?
                  <Button
                    sx={{
                      backgroundColor: "#6799CC",
                      color: "white",
                      padding: "7px 30px",
                      borderRadius: "40px",
                      textTransform: "capitalize",
                      fontWeight: "600",
                    }}
                  onClick={() => {createAnalysis()}}
                >
                  Criar análise
                </Button>
                :
                  <div></div>
              }
            
            </>
          }
        </Grid>
      </Grid>
    </Card>
  );
}
