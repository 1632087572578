import React, { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Grid } from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: "transparent",
}));

const AccordionSummary = styled(
  (props: AccordionSummaryProps & { onClickIcon: () => void }) => (
    <MuiAccordionSummary
      onClick={props.onClickIcon}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  )
)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

type Props = {
  title: string;
  titleTypography?: TypographyProps["variant"];
  expanded?: boolean;
  HeaderComponent?: React.FC;
  noDivider?: boolean;
  handleChange?: any;
};

export default function CareerPathAccordion(props: PropsWithChildren<Props>) {
  const { HeaderComponent } = props;

  return (
    <Accordion sx={{ width: "100%" }} expanded={props.expanded}>
      <AccordionSummary
        onClickIcon={props.handleChange}
        sx={{ borderBottom: props.noDivider ? "none" : "3px solid #79AF65" }}
      >
        <Grid
          onClick={props.handleChange}
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            variant={props.titleTypography || "h5"}
            sx={{
              "&:first-letter": {
                textTransform: "capitalize",
              },
            }}
          >
            <b>{props.title}</b>
          </Typography>
          {HeaderComponent && (
            <div tabIndex={0}>
              <HeaderComponent />
            </div>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}
