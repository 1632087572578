import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./resources.json";

i18n.use(initReactI18next).init({
  resources,
  lng: "pt-BR",
  fallbackLng: "en",
  debug: true,
});

export default i18n;
