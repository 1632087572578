import { Axios } from 'axios';
import { Course, Module, Tag } from '../models';
import { Logger } from '../utils';
import { BaseService } from './BaseService';

class CoursesService extends BaseService<Course> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/academy/courses';
  }
}

class ModulesService extends BaseService<Module> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/academy/courses';
  }
}

class TagsService extends BaseService<Tag> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/academy/tags';
  }
}

export class AcademyService {
  courses: CoursesService;
  modules: ModulesService;
  tags: TagsService;

  constructor(http: Axios, l?: Logger) {
    this.courses = new CoursesService(http, l);
    this.modules = new ModulesService(http, l);
    this.tags = new TagsService(http, l);
  }
}
