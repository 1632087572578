import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { Employee, SDK } from "@zrp/client";
import { CriteriaPerLevel, Progress } from '@zrp/client/src/models';
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEmployee } from "src/providers";
import { AnalysisCard, DossierAccordion, EmployeeTitleCard, ProgressHeader } from "./components";

export default function Dossier() {
  const { employee: contextEmployee } = useEmployee();
  const { employeeId } = useParams();
  const userId = employeeId? employeeId: contextEmployee?.id
  const [criteriaPerLevel, setCriteriaPerLevel] = useState<CriteriaPerLevel>();
  const [employee, setEmployee] = useState<Employee>();
  const [progress, setProgress] = useState<Progress>();
  type Role = keyof CriteriaPerLevel;

  const getEmployee = useCallback(async () => {
    return await SDK.employees.get(Number(userId));
  }, [userId]);

  const getEmployeeProgressById = useCallback(async () => {
    if (!employee) return;
    return await SDK.progress.getByEmployeeId(employee.id);
  }, [employee]);

  const getEmployeeCareerPathById = useCallback(async () => {
    if (!employee) return;
    return await SDK.careerPathCriteria.getByEmployeeId(employee.id);
  }, [employee]);


  useEffect(() => {
    const fetchEmployee = async () => {
      const result = await getEmployee();
      if (!result) throw new Error("Funcionário não encontrado.");
      setEmployee(result);
    }
    try {
      if (!userId) return;
      fetchEmployee();
    } catch (error: any) {
      toast.error(`Ops! Houve um erro ao buscar os dados: ${ error.message }`);
    }
  }, [userId, getEmployee]);

  useEffect(() => {
    const getProgress = async () => {
      if (!employee) return;
      try {
        const res = await getEmployeeProgressById();
        setProgress(res);
      } catch (error: any) {
        toast.error(`Ops! Houve um erro ao buscar os dados: ${ error.message }`);
      }
    }

    const getCriteria = async () => {
      if (!employee) return;
      try{
        const res = await getEmployeeCareerPathById();
        setCriteriaPerLevel(res);
      } catch(error: any) {
        toast.error(`Ops! Houve um erro ao buscar os dados: ${ error.message }`);
      }
    }

    getProgress();
    getCriteria();
  }, [employee, getEmployeeProgressById, getEmployeeCareerPathById]);

  return (
    <Fragment>
      <Grid container spacing={{ xl: 0, xs: 0 }} justifyContent="center">
        <Grid
          container
          justifyContent="center"
          xs={10}
          sx={{ backgroundColor: "transparent" }}
        >
          <Grid container justifyContent="space-between" xs={12} spacing={4}>
            <Stack gap={1}>
              <Stack direction="row" alignItems="center"/>
            </Stack>
            <Grid>
              { employee
                ? <EmployeeTitleCard employee={ employee } />
                : <div></div>
              }
            </Grid>
          </Grid>
          <Grid xs={11}>
            <Grid sx={ { margin: "20px 0px" } }>
              <ProgressHeader
                progress={ progress !== undefined ? progress.total.progress : 0 }
              />
            </Grid>
            {
              criteriaPerLevel
              ? (['learner', 'executer', 'teacher'] as Role[]).map((role) => {
                  const levelCriteria = criteriaPerLevel[role];
                  if(Array.isArray(levelCriteria)){
                    const levelName = levelCriteria[0]?.level ?? '';
                    return <DossierAccordion
                        title={levelName}
                        progress={ progress?.levels?.find(l => l.name === levelName)?.progress }
                      >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          { employee ? (levelCriteria.map(criteria => <AnalysisCard criteria={criteria} employee={employee} />)) : (<div></div>)}
                        </Grid>
                      </Grid>
                    </DossierAccordion>
                  }
                  return <div></div>
                }
                )
              : <div></div>
            }
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
