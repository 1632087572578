import styled from "styled-components";
import MuiTextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

export const InputWrapper = styled.div`
  width: 100%;
`;

type LabelProps = {
  error: boolean;
};

export const Label = styled(InputLabel)<LabelProps>`
  display: flex;
  align-items: center;

  &.MuiInputLabel-root {
    span {
      margin-left: 10px;
      color: ${props => props.error && props.theme.palette.error.main};
    }
    svg {
      fill: ${props => props.error && props.theme.palette.error.main};
    }
  }
`;

export const TextField = styled(MuiTextField)`
  width: 100%;

  &.MuiTextField-root {
    .MuiOutlinedInput-root {
      border-radius: 100px;
    }
  }
`;

export const RoundedTextField = styled(MuiTextField)`
  &.MuiTextField-root {
    & fieldset {
      border-color: #9CCC62;
    };
    &:hover fieldset {
      border-color: #9CCC62;
    };
    &.Mui-focused fieldset {
      border-color: #9CCC62;
    };
    .MuiOutlinedInput-root {
      border-radius: 100px;
    }
  }
`