type IndexableRecord<T> = T & Record<string, any>;

/**
 * Sort any given IndexableRecord set
 *
 * @param data
 * @param order
 * @param field
 * @param cast
 * @returns
 */
export const sortBy = <T>(
  data: Array<IndexableRecord<T>> = [],
  order: "asc" | "desc" = "asc",
  field: string = "id",
  cast?: (value: any) => number
): Array<T> => {
  return data.sort((a: IndexableRecord<T>, b: IndexableRecord<T>) => {
    const av = cast ? cast(a[field]) : a[field];
    const bv = cast ? cast(b[field]) : b[field];

    const result = av > bv ? -1 : 1;
    return (order === "asc" ? 1 : -1) * result;
  });
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep<T>(target: T, ...sources: T[]): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target?.[key]) Object.assign(target as any, { [key]: {} });
        mergeDeep(target?.[key], source[key]);
      } else {
        Object.assign(target as any, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
