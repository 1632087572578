import { Axios } from 'axios';
import { Department } from 'src/models/Department';
import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class DepartmentService extends BaseService<Department> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/departments';
  }
}
