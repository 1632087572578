import React, { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useEmployee } from "src/providers";


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: "transparent",
}));

const AccordionSummary = styled(
  (props: AccordionSummaryProps & { onClickIcon: () => void }) => (
    <MuiAccordionSummary
      onClick={props.onClickIcon}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  )
)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

type Props = {
  title: string;
  titleTypography?: TypographyProps["variant"];
  expanded?: boolean;
  HeaderComponent?: React.FC;
  noDivider?: boolean;
  handleOptionsEdit: () => any;
  handleChange: (value: boolean) => any;
  handleActivateTopic: () => any;
  isTopicActive: boolean;
};

export default function CriteriaAccordion(props: PropsWithChildren<Props>) {
  const { canAccessOptions } = useEmployee();
  const [expanded, setExpanded] = React.useState<boolean>(
    Boolean(props.expanded)
  );

  const handleChange = () => {
    setExpanded(!expanded);
    props.handleChange(!expanded);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);
  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { HeaderComponent } = props;

  return (
    <Accordion sx={{ width: "100%" }} expanded={expanded}>
      <AccordionSummary
        onClickIcon={handleChange}
        sx={{ borderBottom: props.noDivider ? "none" : "3px solid #79AF65" }}
      >
        <Grid
          onClick={handleChange}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant={props.titleTypography || "h5"}
            sx={{
              "&:first-letter": {
                textTransform: "capitalize",
              },
            }}
          >
            <b>{props.title}</b>
          </Typography>
          <div>
            {canAccessOptions && <IconButton onClick={handleOptionsClick} aria-label="delete" style={{ color: props.isTopicActive ? 'green' : 'red' }}>
              <MoreVert></MoreVert>
            </IconButton>}
            <Menu open={optionsOpen} onClose={handleClose} anchorEl={anchorEl}>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  props.handleOptionsEdit();
                  setAnchorEl(null);
                }}
              >
                Editar
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  props.handleActivateTopic();
                  setAnchorEl(null);
                }}
              >
                {props.isTopicActive ? "Inativar" : "Ativar"}
              </MenuItem>
            </Menu>
          </div>
          {HeaderComponent && (
            <div tabIndex={0}>
              <HeaderComponent />
            </div>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}
