import { Grid, LinearProgress, Typography } from "@mui/material";
import { Fragment } from "react";

export default function ProgressHeader(props: { progress: number }) {
  const { progress } = props;

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h6">Progresso Total</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" align="right">{ progress }%</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            color="info"
            value={ progress }
            sx={ {
              height: 9,
              borderRadius: 5,
            } }
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}
