/* eslint-disable no-unused-vars */
import { Department } from "./Department";

export enum CareerLevel {
  LEARNER = "learner",
  EXECUTER = "executer",
  TEACHER = "teacher",
}

export type Criteria = {
  id: number;
  name: string;
  expectation: string;
  objective: string;
  level: CareerLevel;
  department: Department;
  active: boolean;
};
