import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  confirmDialog: any,
  setConfirmDialog: any;
}

function ConfirmDialog({
  confirmDialog,
  setConfirmDialog
}: ConfirmDialogProps): JSX.Element {
  return (
    <Dialog
      open={confirmDialog.isOpen}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Confirmação
      </DialogTitle>
      <DialogContent>
        {confirmDialog.message}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined" 
          color="error"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          Não
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={confirmDialog.onConfirm}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
