import { Axios } from 'axios';
import { Objective } from '../models/Objective';

import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class ObjectivesService extends BaseService<Objective> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/objectives';
  }
}
