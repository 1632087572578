import { Axios } from 'axios';
import { CapResponse } from 'src/models/Cap';

import { Logger } from '../utils/logger';
import { BaseService } from './BaseService';

export class CapsService extends BaseService<CapResponse> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/caps';
  }
}
