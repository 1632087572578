import { Search } from "@mui/icons-material";
import {
  Grid,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";

export default function DossierGeneralFiltersBar(props: {
  handleNameFilter: Function,
  handlePdcLevelFilter: Function,
  handleDepartmentFilter: Function,
  handleSortingMethod: Function,
  selectedPdcLevel: string | null,
  selectedDepartment: string | null,
  selectedSortingMethod: string | null,
  resultCounter?: number
}) {
  const { 
    handleNameFilter,
    handlePdcLevelFilter,
    handleDepartmentFilter,
    handleSortingMethod,
    selectedPdcLevel,
    selectedDepartment,
    selectedSortingMethod,
    resultCounter,
  } = props;

  return (
    <Fragment>
      <Grid>
        <Grid container gap={2} alignItems="center">
          <OutlinedInput
            sx={{ borderRadius: "50px" }}
            placeholder={"Busca por nome"}
            startAdornment={
              <InputAdornment position="start">
                <Search></Search>
              </InputAdornment>
            }
            onChange={e => handleNameFilter(e)}
          />
          <TextField
            select
            sx={{ minWidth: "180px" }}
            onChange={e => handlePdcLevelFilter(e)}
            label="Nível de Carreira"
            value={selectedPdcLevel}
          >
            <MenuItem value={""}>Todos os níveis</MenuItem>
            <MenuItem value={"Learner"}>Learner</MenuItem>
            <MenuItem value={"Executer"}>Executer</MenuItem>
            <MenuItem value={"Teacher"}>Teacher</MenuItem>
          </TextField>
          <TextField
            select
            sx={{ minWidth: "280px" }}
            onChange={e => handleDepartmentFilter(e)}
            label="Área"
            value={selectedDepartment}
          >
            <MenuItem value={""}>Todos as áreas</MenuItem>
            <MenuItem value={"Desenvolvimento"}>Desenvolvimento</MenuItem>
            <MenuItem value={"Design"}>Design</MenuItem>
            <MenuItem value={"Product owner"}>Product Owner</MenuItem>
            <MenuItem value={"Quality assurance"}>Quality Assurance</MenuItem>
          </TextField>
          {resultCounter !== undefined && (
            <Typography sx={{ display: "inline-block" }} variant="body1">
              <b>{resultCounter} Resultados</b>
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item alignItems="center">
        <Grid container gap={2} alignItems="center">
          <Typography sx={{ display: "inline-block" }} variant="body1">
            Ordenar por:
          </Typography>
          <TextField
            select
            sx={{ minWidth: "280px" }}
            onChange={e => handleSortingMethod(e)}
            value={selectedSortingMethod}
          >
            <MenuItem value="ASC">Ordem alfabética ascendente</MenuItem>
            <MenuItem value="DESC">Ordem alfabética descendente</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Fragment>
  );
}
