/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Typography,
  Container,
  Divider,
  Snackbar,
  SnackbarOrigin,
  Alert,
} from "@mui/material";
import LoginButton from "../../components/LoginButton";
import { useWindowDimensions } from "../../hooks";

import { useEffect, useState } from "react";

import { useAuth } from "../../providers";

import { useLocation, useNavigate, To } from "react-router";
import ZRPIcon from "../../components/ZRPIcon";

interface State {
  open: boolean;
  error: string;
}

export default function LoginPage() {
  const location = useLocation();
  const nav = useNavigate();

  const [state, setState] = useState<State & SnackbarOrigin>({
    open: false,
    vertical: "top",
    horizontal: "right",
    error: "",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const { error, authenticated } = useAuth();
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (!error) return;

    if (error?.message?.match("não está registrado")) {
      setState({ ...state, error: error.message, open: true });
    } else {
      setState({
        ...state,
        error:
          "O login está indisponível ou você não está autorizado a acessar essa aplicação!",
        open: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (authenticated) {
      const state = location.state as { from?: { pathname?: string } };

      const from: To = {
        pathname: (state?.from?.pathname as string) ?? "/",
      } as To;

      nav(from, { replace: true });
    }
  }, [authenticated]);

  return (
    <Grid
      sx={{ height: "100vh", width: "100vw", p: 1 }}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        sx={{ maxWidth: 450 }}
        open={open}
        onClose={handleClose}
        autoHideDuration={15000}
        key="login-error"
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          <Typography variant="body2">{state.error}</Typography>
        </Alert>
      </Snackbar>

      <Paper
        elevation={16}
        sx={{
          display: "flex",
          width: dimensions.width < 600 ? "100%" : "80%",
          height: dimensions.width < 600 ? "100%" : "80%",
          alignContent: "center",
          overflow: "hidden",
          borderWidth: 4,
          borderColor: "#000",
          borderStyle: "solid",
        }}
      >
        <Grid
          item
          display={{ xs: "none", sm: "none", md: "flex" }}
          md={7}
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
          }}
        >
          <img
            style={{
              width: "395px",
              height: "400px",
              opacity: "0.7",
            }}
            src="assets/pages/login/team.png"
            alt="Imagem do time"
          >
          </img>
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            height: "100%",
            background: (theme) => theme.palette.background.default,
          }}
        >
          <Container
            sx={{
              width: "100%",
              position: "relative",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              fontWeight={700}
              sx={{ mb: 2 }}
            >
              ZRPlataforma
            </Typography>
            <Typography
              variant="h6"
              component="h1"
              fontWeight={700}
              sx={{ mb: 2 }}
            >
              Utilize as opções abaixo para fazer login.
            </Typography>
            <Divider />
            <Typography mt={2} mb={1}>
              Bem-vindo à plataforma da ZRP (a ZRPlataforma 👀)
            </Typography>
            <Typography mb={4}>
              Para efetuar o seu login utilize o botão abaixo. É necessário que
              você possua um e-mail cadastrado como colaborador ou parceiro da
              ZRP para entrar.
            </Typography>
            <ZRPIcon
              sx={{
                width: 90 / 2,
                height: 69 / 2,
                position: "absolute",
                bottom: 16,
                right: 16,
              }}
            />
            <LoginButton />
          </Container>
        </Grid>
      </Paper>
    </Grid>
  );
}
