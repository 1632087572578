import { AnalysisCommentary } from '@zrp/client/src/models';
import { Grid, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEmployee } from 'src/providers/EmployeeProvider';
import moment from 'moment';

function formatDate(date: Date): string {
  return moment(date).format('lll');
}

export default function CommentHistory(
  props: { commentary: AnalysisCommentary | undefined, deleteCommentary: (commentaryId: number | undefined) => void}
  ): 
  JSX.Element {
    const { canAccessOptions } = useEmployee();
    const { commentary, deleteCommentary } = props

    return (
      <Grid>
        { commentary ? 
        <>
        <Grid container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ color: "#A4A4AD" }} variant="subtitle2">
            <b>
              {`- Comentário criado em ${commentary.updatedAt ? formatDate(new Date(commentary.updatedAt)) : '-'} - `
              + commentary.updatedBy}
            </b>
          </Typography>
        </Grid>
        <Grid container
          direction="row"
          justifyContent="space-between">
        <Typography>
          { commentary.comment }
        </Typography>
          {canAccessOptions
            ?
              <IconButton aria-label="delete" color="error"
              onClick={() => {deleteCommentary(commentary.id)}}>
                <DeleteIcon />
              </IconButton>
            :
              <div></div>
           }
        </Grid>
       </>
       : <div></div> 
       }
      </Grid>
    );
}
