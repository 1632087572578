import React, { useState } from "react";
import { useWindowDimensions } from "../../hooks";

import {
  Box,
  Drawer as MuiDrawer,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  PaperProps,
  Theme,
  ListItemText,
  DrawerProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Menu from "./Menu";
import UserProfile from "./UserProfile";
import ZRPIcon from "../ZRPIcon";
import { MUIStyledCommonProps } from "@mui/system";

const drawerWidth = 300;

const openedMixin = (theme: Theme | undefined) => {
  if (theme) {
    return {
      width: drawerWidth,
      height: `calc(100% - ${theme.spacing(4)})`,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    };
  } else {
    return {};
  }
};

const closedMixin = (theme: Theme | undefined) => {
  if (theme) {
    return {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      height: `calc(100% - ${theme.spacing(4)})`,
      width: `calc(${theme.spacing(8)} + 1px)`,
    };
  } else {
    return {};
  }
};

const drawerStyling = ({
  theme,
  open,
}: DrawerProps & MUIStyledCommonProps<Theme>): any => {
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  };
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(drawerStyling);

export default function CustomDrawer() {
  const dimensions = useWindowDimensions();
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(
    dimensions.width > 768
  );

  const toggleDrawer = (open?: boolean) => (event?: any) => {
    if (
      event &&
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open !== undefined && open != null ? open : !isDrawerOpen);
  };

  const paperProps: PaperProps = {
    elevation: 0,
    sx: {
      m: 2,
      overflowX: "hidden",
      overflow: "visible",
      borderColor: "transparent",
      borderWidth: 0,
      borderRadius: theme => theme.spacing(4),
    },
  };

  const content = (
    <React.Fragment>
      <Menu open={isDrawerOpen}></Menu>
      <Box
        sx={{
          p: 0,
          m: 0,
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <Divider sx={{ mt: 2 }}></Divider>
        <UserProfile sx={{ paddingBottom: 4, paddingTop: 2 }} />
      </Box>
    </React.Fragment>
  );

  return (
    <Drawer
      sx={{ ...paperProps.sx, marginRight: 0 }}
      PaperProps={paperProps}
      variant="permanent"
      open={isDrawerOpen}
    >
      <List sx={{ padding: 0 }}>
        <ListItemButton
          key="collapse"
          onClick={toggleDrawer()}
          sx={{ height: 70 }}
        >
          <ListItemIcon>
            <ZRPIcon
              sx={{
                fontSize: theme => theme.spacing(4),
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontWeight: 700,
            }}
            primary="ZRPlataforma"
          ></ListItemText>
        </ListItemButton>
      </List>
      <Divider sx={{ mb: 2 }}></Divider>
      {content}
    </Drawer>
  );
}
