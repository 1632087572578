/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ZRPIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 90 69" {...props}>
      <path d="M69.7241 48.8353L69.2069 49.413C63.4828 56.1078 55.1035 59.9479 46.2069 59.9479C45.6897 59.9479 45.1379 59.9479 44.6207 59.9139V64.6376L39 59.0983C32.6207 57.5351 26.862 53.8988 22.7241 48.8353C18.4138 43.5339 15.4827 37.043 15.4827 30.2463C15.4827 17.5365 24.3448 6.38997 36.5172 2.27797L36.862 2.17603L37.1724 2.34594C41.7586 4.86072 44.5862 9.65238 44.5862 14.7839V24.0954L69.7241 48.8353Z" />
      <path d="M90 60.5256L89.2758 61.1373C77.4138 71.4003 59.3104 70.8906 48.138 59.8799C43.5174 55.3261 40.6209 49.515 39.6553 43.1601C38.7588 37.0091 39.8967 30.6202 42.7932 25.1828L42.9656 24.877L43.3105 24.775C48.3104 23.4497 53.7242 24.809 57.3794 28.4112L90 60.5256Z" />
      <path d="M41.6206 22.872C37.5517 27.5957 32.6551 28.3433 29.2069 28.3093H29.1723H29.1379L0 28.0035L19.6552 4.92868C19.7931 4.6908 21.3448 2.65179 23.931 1.36042C25.6551 0.510833 28.9655 -0.338745 32.0689 0.137024C32.0689 0.137024 42.9654 1.29246 44.5171 12.541C45.1034 16.7549 43.6206 20.5271 41.6206 22.872Z" />
    </SvgIcon>
  );
}
