import { Axios } from 'axios';
import { AnalysisUpdate } from 'src/models/AnalysisUpdate';
import { Analysis, AnalysisCommentary } from '../models';
import { Logger } from '../utils';
import { BaseService } from './BaseService';

export class AnalysisCommentaryService extends BaseService<AnalysisCommentary> {
  getByAnalysisId: (id: string | number) => Promise<AnalysisCommentary[]>;
  deleteById = async (commentaryId: number): Promise<AnalysisCommentary> => {
    const response = await this.http.delete(`${this.basePath}/commentary/${commentaryId}`);
    return response as any;
  }
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/analysis';
    this.getByAnalysisId = async (
      id: string | number,
    ): Promise<AnalysisCommentary[]> => {
      const response = await this.http.get(`${this.basePath}/${id}/commentary`);
      const { data } = JSON.parse(response.data);
      return data as any;
    };
  }

  patchByAnalysisId = async (body: AnalysisCommentary, commentaryId?: number | string
  ): Promise<AnalysisCommentary> => {
    const response = await this.update(body, `commentary/${commentaryId}`);
    return response as AnalysisCommentary;
  };

  createByAnalysisId = async (body: AnalysisCommentary, analysisId: number | string
    ): Promise<AnalysisCommentary> => {
      const response = await this.create(body, `${analysisId}/commentary`);
      return response as AnalysisCommentary;
    };
  }

export class AnalysisHistoryService extends BaseService<AnalysisUpdate> {
  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/analysis';
  }
  getLastUpdate = async(id: number | string): Promise<AnalysisUpdate> => {
    const response =  await this.http.get(`${this.basePath}/${id}/history/lastUpdate`);
    const { data } = JSON.parse(response.data);
    return data;
  };

  getAllUpdates = async(id: number | string): Promise<AnalysisUpdate[]> => {
    const response =  await this.http.get(`${this.basePath}/${id}/history`);
    const { data } = JSON.parse(response.data);
    return data;
  };

  getAllStatusUpdates = async(id: number | string): Promise<AnalysisUpdate[]> => {
    const response =  await this.http.get(`${this.basePath}/${id}/history/statusUpdates`);
    const { data } = JSON.parse(response.data);
    return data;
  };
}
export class AnalysisStatus extends BaseService<Partial<Analysis>> {
  constructor(http: Axios, l?: Logger) {
   super(http, l);
   this.basePath = '/analysis';
 }
  patchStatus = async(body: Partial<Analysis>): Promise<Partial<Analysis>> => {
    const response = await this.update(body, `${body.id}`);
    return response
  }
}
export class AnalysisService extends BaseService<Analysis> {
  commentary: AnalysisCommentaryService;
  history: AnalysisHistoryService;
  status: AnalysisStatus;
  getByEmployeeId = async (employeeId: number): Promise<Analysis[]> => {
    const response = await this.http.get(
      `${this.basePath}/employee/${employeeId}`,
    );
    const { data } = JSON.parse(response.data);
    return data;
  };
  createByCriteriaId = async (analysis: Analysis): Promise<Analysis> => {
    const response = await this.create(
      analysis, `${analysis.criteriaId}`
    )
    return response
  }

  constructor(http: Axios, l?: Logger) {
    super(http, l);
    this.basePath = '/analysis';
    this.commentary = new AnalysisCommentaryService(http, l);
    this.history = new AnalysisHistoryService(http, l);
    this.status = new AnalysisStatus(http, l);
  }
}

