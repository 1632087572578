import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "src/components/Input";
import SortIcon from "@mui/icons-material/Sort";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import CloudIcon from "@mui/icons-material/Cloud";
import { Typography } from "@mui/material";
import CloseCriteriaFormModal from "./CloseCriteriaFormModal";
import * as S from "./styles";
import { CareerLevel, Criteria } from "src/types/entities/CareerPath";
import { SDK } from "@zrp/client";
import { Severity } from "src/components/displayer/Snackbar/styles";

type Props = {
  action: string;
  handleClose: () => void;
  level: CareerLevel;
  departmentId: number;
  handleSubmitCb: (criteria: Criteria) => void;
  handleSnackbarData: (data: {
    open: boolean;
    message: string;
    severity: Severity;
  }) => void;
  formDataCriteria?: Criteria | null;
};

type FormValues = {
  name: string;
  expectation?: string;
  objective?: string;
};

const CriteriaForm = (props: Props) => {
  const [showCloseModal, setShowCloseModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: props.formDataCriteria?.name || "",
      expectation: props.formDataCriteria?.expectation || "",
      objective: props.formDataCriteria?.objective || "",
    },
  });

  interface CriteriaData extends FormValues {
    level: string;
    departmentId: number;
    id?: number | null;
    active: boolean;
  }

  const onSubmit = async (data: FormValues) => {
    try {
      let criteriaData: CriteriaData = {
        ...data,
        level: props.level,
        departmentId: props.departmentId,
        active:
          typeof props.formDataCriteria?.active === "boolean"
            ? props.formDataCriteria.active
            : true,
      };

      const criteria =
        props.action === "create"
          ? await SDK.careerPathCriteria.create(criteriaData)
          : await SDK.careerPathCriteria.patchByCriteriaData({
              ...criteriaData,
              id: props.formDataCriteria?.id || null,
            });

      props.handleSubmitCb(criteria);
      props.handleSnackbarData({
        open: true,
        message: `Tópico ${
          props.action === "create" ? "criado" : "editado"
        } com sucesso`,
        severity: "success",
      });
      props.handleClose();
    } catch (error) {
      props.handleSnackbarData({
        open: true,
        message: `Tópico não pôde ser ${
          props.action === "create" ? "criado" : "editado"
        }`,
        severity: "danger",
      });
    }
  };

  const actionText = (createText: string, editText: string): string => {
    return props.action === "create" ? createText : editText;
  };

  return (
    <S.Form>
      <S.FormHeader>
        <Typography variant="h5">
          {actionText("Novo", "Editar")} tópico
        </Typography>
        <S.ButtonWrapper>
          <S.TextButton onClick={() => setShowCloseModal(true)}>
            Cancelar
          </S.TextButton>
          <S.Button onClick={handleSubmit(onSubmit)}>
            {actionText("Criar", "Salvar")}
          </S.Button>
        </S.ButtonWrapper>
      </S.FormHeader>

      <Controller
        name="name"
        control={control}
        rules={{ required: "Digite um título" }}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Título*:"
            icon={SortIcon}
            placeholder="Digitar título"
            value={value}
            onChange={onChange}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
          />
        )}
      />

      <Controller
        name="objective"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Objetivo:"
            icon={GpsFixedIcon}
            placeholder="Digitar objectivo"
            value={value}
            onChange={onChange}
            error={Boolean(errors.objective?.message)}
            helperText={errors.objective?.message}
          />
        )}
      />

      <Controller
        name="expectation"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Expectativa:"
            icon={CloudIcon}
            placeholder="Digitar expectativa"
            value={value}
            onChange={onChange}
            error={Boolean(errors.expectation?.message)}
            helperText={errors.expectation?.message}
          />
        )}
      />
      <CloseCriteriaFormModal
        open={showCloseModal}
        handleClose={() => setShowCloseModal(false)}
        handleCloseForm={props.handleClose}
      />
    </S.Form>
  );
};

export default CriteriaForm;
