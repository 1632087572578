export enum CareerLevel {
  LEARNER = "learner",
  EXECUTER = "executer",
  TEACHER = "teacher",
}

export enum CareerAnalysisStatus {
  InProgress = "InProgress",
  Completed = "Completed",
  NotCompleted = "NotCompleted",
  NotValidated = "NotValidated",
}

export interface Analysis {
  id: number;
  status: CareerAnalysisStatus;
  criteriaId: number;
  employeeId: number;
  criteria: Criteria;
  commentaries?: AnalysisCommentary[]
  lastUpdate: AnalysisUpdate;
  createdAt: Date;
  updatedAt: Date;
}

export interface Criteria {
  level: any;
  id: number;
  name: string;
  expectation: string;
  objective: string;
  active: true;
  createdAt: Date;
  updatedAt: Date;
}

export interface AnalysisCommentary {
  id: number;
  comment: string;
  createdBy: string;
  updatedBy: string;
  restricted: boolean;
  analysisId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface AnalysisUpdate {
  to?: Audit
  from?: string;
}

export interface Audit {
  id: number;
  changes: JSON;
  relationId: number;
  relationName: string;
  employeeId: number;
  createdAt: Date;
  updatedAt: Date;
}