import React from "react";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { CssBaseline } from "@mui/material";

import { darkThemeOptions, themeOptions, lightThemeOptions } from "../config";
import { mergeDeep } from "../utils/data";

export type ColorScheme = "dark" | "light";

export interface DynamicThemeProviderProps {
  children: React.ReactNode;
  colorScheme?: ColorScheme;
}

export interface DynamicThemeContextType {
  currentColorScheme: ColorScheme;
  changeTheme: (colorScheme: ColorScheme) => void;
}

const DynamicThemeContext = React.createContext<DynamicThemeContextType>(null!);

export function DynamicThemeProvider({
  children,
  ...props
}: DynamicThemeProviderProps) {
  const isDarkModeEnabled = window.localStorage.getItem("darkMode");

  if (isDarkModeEnabled == null) {
    window.localStorage.setItem("darkMode", "false");
  }

  const [colorScheme, setColorScheme] = React.useState<ColorScheme>(
    props.colorScheme ?? isDarkModeEnabled === "true" ? "dark" : "light"
  );

  const theme = createTheme(
    mergeDeep<ThemeOptions>(
      {},
      themeOptions,
      colorScheme === "light" ? lightThemeOptions : darkThemeOptions
    )
  );

  window.addEventListener("storage", () => {
    setColorScheme(
      window.localStorage.getItem("darkMode") === "true" ? "dark" : "light"
    );
  });

  const changeTheme = (colorScheme: ColorScheme) => {
    window.localStorage.setItem(
      "darkMode",
      colorScheme === "dark" ? "true" : "false"
    );
    setColorScheme(colorScheme === "dark" ? "dark" : "light");
  };

  const value = {
    currentColorScheme: colorScheme,
    changeTheme,
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>
        <CssBaseline />
        <DynamicThemeContext.Provider value={value}>
          {children}
        </DynamicThemeContext.Provider>
      </StyledComponentsThemeProvider>
    </ThemeProvider>
  );
}

export function useDynamicTheme() {
  return React.useContext(DynamicThemeContext);
}
