import { Criteria } from "@zrp/client";
import { Cloud, GpsFixed } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

export default function CriteriaDescription(props: { criteria: Criteria }): JSX.Element {
  const { criteria } = props

  return (
    <Card
      sx={{
        backgroundColor: "rgba(217,217,217, 0.1)",
        borderRadius: "20px",
        p: 4,
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <Grid sx={{ display: "flex", flexFlow: "column wrap" }}>
          <Typography variant="caption">
            <b>{criteria.name}</b>
          </Typography>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid sx={{ display: "flex", flexFlow: "column wrap" }}>
          <Typography
            variant="caption"
            color="info.main"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", xl: "start" },
              marginTop: "5px",
            }}
          >
            <Cloud sx={{ width: 16, height: 16 }}></Cloud>
            &nbsp;&nbsp;<b>Expectativa</b>:
          </Typography>
          <Typography variant="caption">{criteria.expectation}</Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexFlow: "column wrap",
            margin: "20px 0",
          }}
        >
          <Typography
            variant="caption"
            color="info.main"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", xl: "start" },
              marginTop: "5px",
            }}
          >
            <GpsFixed sx={{ width: 16, height: 16 }}></GpsFixed>
            &nbsp;&nbsp;<b>Objetivo:</b>
          </Typography>
          <Typography variant="caption">{criteria.objective}</Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
