import {
  Avatar,
  Typography,
  Stack,
  SxProps,
  List,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import { useNavigate } from "react-router";

import { useAuth } from "../../providers";

export default function UserProfile(props: { sx: SxProps }) {
  const { user } = useAuth();
  const nav = useNavigate();

  const onClick = () => nav("/profile");

  return (
    <List sx={{ padding: 0 }}>
      <ListItemButton sx={{ ...props.sx }} onClick={onClick}>
        <ListItemIcon>
          {user?.picture ? (
            <Avatar
              sx={{
                width: (theme) => theme.spacing(4),
                height: (theme) => theme.spacing(4),
              }}
              src={user?.picture}
            />
          ) : (
            <Avatar
              sx={{
                width: (theme) => theme.spacing(4),
                height: (theme) => theme.spacing(4),
              }}
            >
              {user?.initials?.at(0)}
            </Avatar>
          )}
        </ListItemIcon>

        <Stack>
          <Typography sx={{ fontSize: 14 }} fontWeight={700}>
            {user?.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {user?.email}
          </Typography>
        </Stack>
      </ListItemButton>
    </List>
  );
}
