import * as React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as S from "./styles";

const Icons = {
  danger: CancelIcon,
  success: CheckCircleIcon,
};

type Props = {
  handleClose: () => void;
  message: string;
  open: boolean;
  severity: S.Severity;
};

const Snackbar = (props: Props) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.handleClose();
  };

  const Icon = Icons[props.severity];

  return (
    <MuiSnackbar
      open={props.open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <S.Wrapper severity={props.severity}>
        <S.MessageBox>
          <Icon />
          <span>{props.message}</span>
        </S.MessageBox>
      </S.Wrapper>
    </MuiSnackbar>
  );
};

export default Snackbar;
