import React from "react";
import { Grid, InputAdornment, MenuItem, OutlinedInput } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Department } from "src/types/entities/Department";
import { Search } from "@mui/icons-material";

type Props = {
  handleTopicDescriptionFilter: (e: any) => void;
  handleDepartmentFilter: (departmentId: string) => void;
  handleFilterCriteriaDescription: (criteria: string) => void;
  handleCriteriaStatus: (criteriaStatus: string) => void;
  setSearchInputWasCleared: (value: boolean) => void;
  selectedCriteriaActiveStatus: string;
  selectedDepartment: string;
  filteredCriteriaDescription: string;
  departments: Department[];
};

export default function CareerPathFiltersBar(props: Props) {
  const departmentOptions = props.departments.map(i => ({
    value: String(i.id),
    label: i.name,
  }));

  const handleSelectFilter = (e: SelectChangeEvent) => {
    const departmentId = e.target.value;
    props.handleDepartmentFilter(departmentId);
  };

  const handleCriteriaStatus = (e: SelectChangeEvent) => {
    const criteriaStatus = e.target.value;
    props.handleCriteriaStatus(criteriaStatus);
  };

  return (
    <React.Fragment>
      <Grid>
        <Grid container gap={2} alignItems="center">
          <OutlinedInput
            sx={{ borderRadius: "50px" }}
            startAdornment={
              <InputAdornment position="start">
                <Search></Search>
              </InputAdornment>
            }
            onChange={e => {
              props.handleFilterCriteriaDescription(e.target.value)
              if (e.target.value === "") props.setSearchInputWasCleared(true)
            }
            }
          />
          <Select
            sx={{ minWidth: "140px", borderRadius: "50px" }}
            onChange={handleSelectFilter}
            value={props.selectedDepartment}
          >
            {departmentOptions.length ? (
              departmentOptions.map(dep => (
                <MenuItem key={dep.value} value={dep.value}>
                  {dep.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem>Nenhum</MenuItem>
            )}
          </Select>
          <Select
            sx={{ minWidth: "140px", borderRadius: "50px" }}
            onChange={handleCriteriaStatus}
            value={props.selectedCriteriaActiveStatus}
          >
            <MenuItem value={"Ativos"}>Ativos</MenuItem>
            <MenuItem value={"Inativos"}>Inativos</MenuItem>
            <MenuItem value={"Todos"}>Todos</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
