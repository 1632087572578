/* eslint-disable no-constant-condition */
import { useState } from "react";
import {
  Box,
  Snackbar,
  Alert,
  Typography,
  Grid,
  SnackbarOrigin,
  IconButton,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { Outlet } from "react-router";
import { ArrowBack } from "@mui/icons-material";

import { useWindowDimensions } from "../../hooks";
import Drawer from "./Drawer";

const wrapperSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  width: "100vw",
  overflowX: "hidden",
  backgroundColor: (theme) => theme.palette.background.default,
};

const innerWrapperSx: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
  paddingRight: 2,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 2,
  overflowX: "hidden",
  width: "100%",
  marginRight: "-6px",
  overflowY: "scroll",
};

// const Controls = styled(Box, {
//   shouldForwardProp: (prop) => prop === "size",
// })(({ theme, ...props }) => {
//   return {
//     position: "absolute",
//     width: "large" ? "50%" : "calc(100% - 32px)",
//     margin: "large" ? 0 : theme.spacing(2),
//     height: "100px",
//     bottom: theme.spacing(4),
//     left: "large" ? "25%" : "0",
//     background: theme.palette.primary.main,
//     borderRadius: theme.spacing(4),
//   };
// });

interface State {
  open: boolean;
}

export default function Layout() {
  const dimensions = useWindowDimensions();

  const [state, setState] = useState<State & SnackbarOrigin>({
    open: true,
    vertical: dimensions.width > 768 ? "top" : "bottom",
    horizontal: dimensions.width > 768 ? "right" : "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const goBack = () => window.history.back();

  return (
    <Box component="main" sx={wrapperSx}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        sx={{ maxWidth: 450 }}
        open={open}
        onClose={handleClose}
        autoHideDuration={15000}
        key="platform-warning"
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          <Typography variant="body2">
            A plataforma ainda está numa versão extremamente inicial, e por
            isso, inconsistências e problemas podem aparecer!
          </Typography>
          <br />
          <br />
          <Typography variant="body2" fontWeight={700}>
            Encontrou algum bug?{" "}
          </Typography>
          <Typography variant="body2">
            Avise o @p ou grite no #grande-conselho.
          </Typography>
        </Alert>
      </Snackbar>

      {/* Start layout components */}
      <Drawer />
      <Box sx={innerWrapperSx}>
        <Grid item xs={12}>
          <IconButton onClick={goBack}>
            <ArrowBack />
          </IconButton>
        </Grid>
        <Outlet />
      </Box>
      {/* <Controls>
        <button onClick={goBack}>voltar</button>
      </Controls> */}
    </Box>
  );
}
