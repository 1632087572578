/* eslint-disable import/no-anonymous-default-export */
import { KeycloakConfig } from "keycloak-js";

export default {
  auth0: {
    domain: "docs-zrp.us.auth0.com",
    clientId: "0ZSuqrkNdtnMNa4Efk7voe9WeDyQcaN8",
  },
  keycloak: {
    realm: "ZRP",
    clientId: "zrp-interface",
    url: "https://auth.zrp.com.br",
  } as KeycloakConfig,
};
