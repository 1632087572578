import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FingerprintOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useAuth } from "../providers";

const LoginButton = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const onClick = async () => {
    setLoading(true);
    await login({});
    setLoading(false);
  };

  return (
    <LoadingButton
      sx={{ textTransform: "none" }}
      variant="outlined"
      size="large"
      loading={loading}
      startIcon={<FingerprintOutlined />}
      onClick={onClick}
    >
      <Typography variant="h6">Login com a ZRP</Typography>
    </LoadingButton>
  );
};

export default LoginButton;
