import { Chip, Typography } from "@mui/material";
import { Fragment } from "react";

export default function AnalysisStatusChip(props: { status: string }): JSX.Element {
  type StatusData = {
    backgroundColor: any,
    fillColor: string,
    label: string,
  }
  const statusData: { [k: string]: StatusData } = {
    InProgress: {
      backgroundColor: "rgb(244, 194, 74, 0.3)",
      fillColor: "#F4C24A",
      label: "Em progresso"
    },
    Completed: {
      backgroundColor: "rgb(120, 175, 101, 0.3)",
      fillColor: "#78AF65",
      label: "Concluído"
    },
    NotCompleted: {
      backgroundColor: "rgb(244, 127, 114, 0.3)",
      fillColor: "#F47F72",
      label: "Não cumprido",
    },
    NotValidated: {
      backgroundColor: "rgb(164, 164, 173, 0.3)", 
      fillColor: "#A4A4AD",
      label: "Não validado",
    }
  }
  const status = props.status ? props.status : 'NotValidated'

  return (
    <Fragment>
      <Chip
        sx={{
          backgroundColor: statusData[status].backgroundColor,
          margin: "10px 0px"
        }}
        label={
          <div>
            <Typography
              sx={{
                color: statusData[status].fillColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  position: "relative",
                  borderRadius: "50%",
                  backgroundColor: statusData[status].fillColor,
                }}
              ></div>
              &nbsp;&nbsp;{ statusData[status].label }
            </Typography>
          </div>
        }
      />
    </Fragment>
  );
}
